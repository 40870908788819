import { ContractFormType } from "../features/contractForm/ContractValidationSchemas";
import { GetExtraArgs, GetExtraArgsWithId } from "../utils/constants";
import { apiSlice, providesList } from "./apiSlice";
import { Client } from "./clientApi";
import { Discount } from "./discountsApi";
import { Machine } from "./machineApi";

export enum BillingType {
	PER_CLIENT = "PER_CLIENT",
	PER_OFFICE = "PER_OFFICE",
	PER_MACHINE = "PER_MACHINE",
	PER_CONTRACT = "PER_CONTRACT",
	NONE = "NONE",
	// PER_CLIENT = "Por cliente",
	// PER_OFFICE = "Por Escritório",
	// PER_MACHINE = "Por máquina",
}

export interface Contract {
	id: number;
	startDate: string;
	endDate: string;
	billingType: BillingType;
	active: boolean;
	ceaseDate: Date;
	createdAt: string;
	updatedAt: string;
}

export enum ContractType {
	vendida = "vendida",
	alugada = "alugada",
	grenke = "grenke",
}

export interface MachineContractDetails {
	id: number;
	contractType: ContractType;
	initialCountsBlack: number;
	initialCountsColor: number;
	blackCopyUnitPrice: number;
	colorCopyUnitPrice: number;
	monthlyFee: number;
	active: boolean;
	contractId: number;
	machineId: number;
	createdAt: string;
	updatedAt: string;

	machine?: Machine;
}

export interface MachineContractAndDetailsWithDiscounts
	extends MachineContractDetails {
	contract: Contract;
	discounts: Discount[];
}

export type ContractWithClient = Contract & { client?: Client };

export type ContractWithRelations = Contract & {
	client?: Client;
	machinesContractDetails?: MachineContractDetailsWithRelations[];
};

export type MachineContractDetailsWithRelations = MachineContractDetails & {
	machine?: Machine;
	discounts?: Discount[];
	contract?: Contract;
};

export const contractApi = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		createContract: build.mutation<Contract, ContractFormType>({
			query: (payload) => ({
				url: "contracts/",
				method: "POST",
				body: payload,
			}),
			invalidatesTags: [{ type: "Contract", id: "LIST" }],
		}),
		updateContract: build.mutation<Contract, ContractFormType>({
			query: (payload) => ({
				url: `/contracts/${payload.contract.id}`,
				method: "PUT",
				body: payload,
			}),
			invalidatesTags: (_result, error, { contract }) =>
				error
					? []
					: [
							{ type: "Contract", id: contract.id },
							{ type: "Machine", id: "LIST" },
					  ],
		}),
		ceaseContract: build.mutation<Contract, number>({
			query: (contractId) => ({
				url: `/contracts/${contractId}/cease`,
				method: "PUT",
			}),
			invalidatesTags: [{ type: "Contract", id: "LIST" }],
		}),

		getAllContracts: build.query<
			ContractWithRelations[],
			GetExtraArgs & {
				startDate?: string;
				endDate?: string;
				customEndFilter?: string;
			}
		>({
			query: (args) => {
				const params = new URLSearchParams();
				Object.entries(args).forEach(([key, value]) => {
					if (
						value &&
						![
							"id",
							"customEndFilter",
							"status",
							"startDate",
							"endDate",
						].includes(key)
					)
						params.append(key, "true");
				});

				// Add custom filters
				if (args.startDate) {
					params.append("startDate", args.startDate);
				}
				if (args.endDate) {
					params.append("endDate", args.endDate);
				}
				if (args.customEndFilter && args.customEndFilter !== "Todos") {
					params.append("customEndFilter", args.customEndFilter);
				}

				return {
					url: `contracts?${params.toString()}`,
				};
			},
			providesTags: (result) => providesList(result, "Contract"),
		}),
		getContractById: build.query<
			ContractWithRelations,
			GetExtraArgsWithId
		>({
			query: (args) => {
				const params = new URLSearchParams();
				Object.entries(args).forEach(([key, value]) => {
					if (value && key !== "id") params.append(key, "true");
				});

				return {
					url: `contracts/${args.id}?${params.toString()}`,
				};
			},
			providesTags: (_result, _error, { id }) => [
				{ type: "Contract", id },
			],
		}),
		getContractDetailsByContractId: build.query<
			MachineContractDetailsWithRelations[],
			GetExtraArgsWithId
		>({
			query: (args) => {
				const params = new URLSearchParams();
				Object.entries(args).forEach(([key, value]) => {
					if (value) params.append(key, "true");
				});

				return {
					url: `/contracts/${args.id}/details?${params.toString()}`,
				};
			},
			providesTags: (_result, _error, { id }) => [
				{ type: "Contract", id },
			],
		}),
		// getContractByClientId: build.query<Contract, number>({
		//   query: (clientId) => `/contracts/client/${clientId}`,
		// }),
	}),
});

// export react hook
export const {
	useUpdateContractMutation,
	useLazyGetContractByIdQuery,
	useGetContractByIdQuery,
	useGetAllContractsQuery,
	useGetContractDetailsByContractIdQuery,
	useCreateContractMutation,
	useCeaseContractMutation,
} = contractApi;
