import { Box } from "@chakra-ui/react";
import Footer from "../components/Footer";
import LandingNavbar from "../components/LandingNavbar";

interface LandingPageLayoutProps {
	children: React.ReactNode;
}

const LandingPageLayout = ({ children }: LandingPageLayoutProps) => {
	return (
		<Box>
			<LandingNavbar />
			{children}
			<Footer />
		</Box>
	);
};

export default LandingPageLayout;
