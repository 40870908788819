import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	CopyCount,
	copyCountsApi,
	CopyCountWithDiff,
} from "../../api/copyCountsApi";
import { previousMonthDate } from "../../utils/dateUtils";
import { Contract } from "../../api/contractApi";
import { isSameMonth, isSameYear, isWithinInterval } from "date-fns";
import { AlertProps } from "@chakra-ui/react";

const today = new Date();

interface AvailabilityPayload {
	isAdmin: boolean;
	year?: number;
	contract?: Contract;
	lastCounts?: CopyCount[] | CopyCountWithDiff[];
}

interface CountData {
	blackCount: number;
	colorCount: number;
	blackCopiesTotalPrice: number;
	colorCopiesTotalPrice: number;
	total: number;
	submitDate: string;
}

interface CopyCountState {
	newCountData: CountData;
	updateCountData: CountData | null;

	newAdminCountData: {
		month: string;
		blackCount: number;
		colorCount: number;
		blackCopyUnitPrice: number;
		colorCopyUnitPrice: number;
		total: number;
		toggle: boolean;
	};

	isSubmitAvailable: boolean;
	// isEditAvailable: boolean;
	editingId: number | null;
	contractNotFoundError: boolean;
	contractEnded: boolean;
	error: string | null;
	isAdmin: boolean;
	// For admin privilege submission
	isContractInactive: boolean;
	isLoading: boolean;
	alerts: AlertProps[];
}

const newCountInitialState: CountData = {
	blackCount: 0,
	colorCount: 0,
	blackCopiesTotalPrice: 0,
	colorCopiesTotalPrice: 0,
	total: 0,
	submitDate: previousMonthDate(today).toISOString(),
};

const newAdminCountDataInitialState = {
	month: "January",
	blackCount: 0,
	colorCount: 0,
	blackCopyUnitPrice: 0,
	colorCopyUnitPrice: 0,
	total: 0,
	toggle: false,
};

const initialState: CopyCountState = {
	newCountData: newCountInitialState,
	updateCountData: null,
	isSubmitAvailable: false,
	// isEditAvailable: true,
	editingId: null,
	contractNotFoundError: false,
	error: null,
	contractEnded: false,
	isAdmin: false,
	newAdminCountData: newAdminCountDataInitialState,
	isContractInactive: false,
	isLoading: false,
	alerts: [],
};

const countsSlice = createSlice({
	name: "counts",
	initialState,
	reducers: {
		setBlackCount: (state, action: PayloadAction<number>) => {
			console.debug("setBlackCount: ", action.payload);
			if (isNaN(action.payload)) return;

			if (state.editingId) {
				if (state.updateCountData) {
					state.updateCountData.blackCount = action.payload;
				}
			} else {
				if (state.newCountData)
					state.newCountData.blackCount = action.payload;
			}
		},
		setColorCount: (state, action: PayloadAction<number>) => {
			console.debug("setColorCount: ", action.payload);
			if (isNaN(action.payload)) return;

			if (state.editingId) {
				if (state.updateCountData) {
					state.updateCountData.colorCount = action.payload;
				}
			} else {
				if (state.newCountData)
					state.newCountData.colorCount = action.payload;
			}
		},

		setEditing: (state, { payload }: PayloadAction<CopyCount | null>) => {
			if (!payload) {
				state.updateCountData = null;
				state.editingId = null;
				return;
			}
			state.editingId = payload.id;
			state.updateCountData = {
				blackCount: payload.blackCount,
				colorCount: payload.colorCount,
				blackCopiesTotalPrice: payload.blackCopiesTotalPrice,
				colorCopiesTotalPrice: payload.colorCopiesTotalPrice,
				total: payload.monthlyFee,
				submitDate: payload.date.toString(),
			};
		},

		setAdmin: (state, action: PayloadAction<boolean>) => {
			state.isAdmin = action.payload;
		},

		checkSubmitAvailability: (
			state,
			{ payload }: PayloadAction<AvailabilityPayload>
		) => {
			const { isAdmin, year, contract, lastCounts } = payload;

			if (!year || !contract || !lastCounts) return;

			state.isSubmitAvailable = isSubmitAvailable(
				isAdmin,
				contract,
				lastCounts[lastCounts.length - 1],
				state
			);
		},

		resetAlerts: (state) => {
			state.alerts = [];
		},
	},
	extraReducers(builder) {
		builder.addMatcher(
			copyCountsApi.endpoints.createCopyCount.matchPending,
			(state) => {
				state.isLoading = true;
			}
		);
		builder.addMatcher(
			copyCountsApi.endpoints.createCopyCount.matchFulfilled,
			(state) => {
				state.newCountData = newCountInitialState;
				state.updateCountData = null;
				state.isSubmitAvailable = false;
				state.contractNotFoundError = false;
				state.editingId = null;
				// state.isEditAvailable = isEditAvailable(state);
				state.isLoading = false;
			}
		);
		builder.addMatcher(
			copyCountsApi.endpoints.createCopyCount.matchRejected,
			(state) => {
				state.isLoading = false;
			}
		);

		builder.addMatcher(
			copyCountsApi.endpoints.updateCopyCount.matchPending,
			(state) => {
				state.isLoading = true;
			}
		);
		builder.addMatcher(
			copyCountsApi.endpoints.updateCopyCount.matchFulfilled,
			(state) => {
				state.newCountData = newCountInitialState;
				state.updateCountData = null;
				state.isSubmitAvailable = false;
				state.contractNotFoundError = false;
				state.editingId = null;
				state.isLoading = false;
			}
		);
		builder.addMatcher(
			copyCountsApi.endpoints.updateCopyCount.matchRejected,
			(state) => {
				state.isLoading = false;
			}
		);
	},
});

export const isSubmitAvailable = (
	isAdmin: boolean,
	contract: Contract,
	lastCount: CopyCount,
	state: CopyCountState
) => {
	if (!contract.active) return false;

	const contractStartDate = new Date(contract.startDate);
	const contractEndDate = new Date(contract.endDate);

	const isWithinContractPeriod = isWithinInterval(today, {
		start: contractStartDate,
		end: contractEndDate,
	});
	if (!isWithinContractPeriod) {
		state.contractEnded = true;
		addAlert(state, {
			status: "warning",
			title: "Contrato terminado",
		});
		console.debug("Not within contract period");
		return false;
	}

	if (lastCount) {
		const lastCountDate: Date = new Date(lastCount.date);
		const submitDate: Date =
			state.editingId && state.updateCountData
				? new Date(state.updateCountData.submitDate)
				: new Date(state.newCountData.submitDate);

		if (
			isSameMonth(submitDate, lastCountDate) &&
			isSameYear(submitDate, lastCountDate)
		) {
			console.debug("Contagem ja submetida");
			return false;
		}

		if (submitDate <= lastCountDate) {
			console.debug("!isSubmitDateAfterLastCount");
			return false;
		}
	}

	// blocks submissions after 15th (admins can)
	const isBeforeMidMonth = today.getDate() <= 15;
	if (!isAdmin && !isBeforeMidMonth) {
		addAlert(state, {
			status: "warning",
			title: "Submissões apenas disponíveis até dia 15",
		});
		console.debug(`O dia de hoje não é anterior a dia 15`);
		return false;
	}

	if (!contract) {
		addAlert(state, {
			status: "warning",
			title: "Contrato não encontrado",
		});

		console.debug("Sem contrato");
		return false;
	}

	state.contractEnded = false;
	return true;
};

const addAlert = (state: CopyCountState, alert: AlertProps) => {
	if (
		!state.alerts.some((existingAlert) =>
			existingAlert.title?.includes(alert.title ?? "")
		)
	) {
		state.alerts.push(alert);
	}
};

export const {
	setBlackCount,
	setColorCount,
	setEditing,
	setAdmin,
	checkSubmitAvailability,
	resetAlerts,
} = countsSlice.actions;

export default countsSlice.reducer;
