import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	useToast,
	VStack,
} from "@chakra-ui/react";
import {
	ExportStatus,
	useUpdateCountExportStatusMutation,
} from "../../api/integrationApi";
import { RefObject } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { getErrorMessage } from "../../utils/helpers";
import { yupResolver } from "@hookform/resolvers/yup";

export const InvoiceConverstionFormSchema = Yup.object().shape({
	invoiceDate: Yup.string().required("Este campo é obrigatório"),
	documentType: Yup.string().required("Este campo é obrigatório"),
	series: Yup.string().required("Este campo é obrigatório"),
	invoiceNumber: Yup.string().required("Este campo é obrigatório"),
	// totalAmount: Yup.string().required("Este campo é obrigatório"),
});

export type InvoiceConverstionForm = Yup.InferType<
	typeof InvoiceConverstionFormSchema
>;

interface InvoiceConversionAlertDialogProps {
	selectedDoc: {
		docNumber?: string;
		docSeries?: string;
		docType?: string;
		copyCountExportIds?: number[];
	};
	onClose: () => void;
	isOpen: boolean;
	cancelRef: RefObject<HTMLButtonElement>;
}

const InvoiceConversionAlertDialog = ({
	selectedDoc,
	isOpen,
	cancelRef,
	onClose,
}: InvoiceConversionAlertDialogProps) => {
	const toast = useToast();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<InvoiceConverstionForm>({
		mode: "all",
		defaultValues: {
			invoiceDate: new Date().toISOString().slice(0, 10),
			documentType: "FAC",
			series: "2",
			invoiceNumber: undefined,
			// totalAmount: 0,
		},
		resolver: yupResolver(InvoiceConverstionFormSchema),
	});

	const [updateExportStatus, { isLoading }] =
		useUpdateCountExportStatusMutation();

	const handleDocInvoiceConversion = async (
		data: InvoiceConverstionForm
	) => {
		const promise = updateExportStatus({
			...selectedDoc,
			status: ExportStatus.Billed,
			invoiceDate: data.invoiceDate,
			invoiceType: data.documentType,
			invoiceSeries: data.series,
			invoiceNumber: data.invoiceNumber,
			// totalAmount: data.totalAmount,
		}).unwrap();
		toast.promise(promise, {
			loading: {
				title: "A atualizar estado",
				description: "Aguarde enquanto processamos o seu pedido.",
			},
			success: { title: "Conversão do documento concluída" },
			error: (err: Error) => {
				const errorMessage = getErrorMessage(
					err as FetchBaseQueryError | SerializedError
				);

				return {
					title: "Erro ao atualizar estado",
					description: errorMessage,
				};
			},
		});

		promise
			.then(() => {
				onClose();
			})
			.catch(() => {
				return;
			});
	};

	return (
		<AlertDialog
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={onClose}
		>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize="lg" fontWeight="bold">
						O documento nº {selectedDoc.docNumber} foi convertido
						em fatura?
					</AlertDialogHeader>

					<AlertDialogBody>
						<form>
							<VStack spacing={4}>
								<Flex gap={4}>
									<FormControl
										isInvalid={
											!!errors?.invoiceDate?.message
										}
										isRequired
									>
										<FormLabel>Data da fatura</FormLabel>
										<Input
											type="date"
											{...register("invoiceDate")}
										/>
										<FormErrorMessage>
											{errors?.invoiceDate?.message}
										</FormErrorMessage>
									</FormControl>
									<FormControl
										isInvalid={
											!!errors?.documentType?.message
										}
										isRequired
									>
										<FormLabel>
											Tipo de documento
										</FormLabel>
										<Input
											type="text"
											{...register("documentType")}
										/>
										<FormErrorMessage>
											{errors?.documentType?.message}
										</FormErrorMessage>
									</FormControl>
								</Flex>
								<Flex gap={4}>
									<FormControl
										isInvalid={!!errors?.series?.message}
										isRequired
									>
										<FormLabel>Serie</FormLabel>
										<Input
											type="text"
											{...register("series")}
										/>
										<FormErrorMessage>
											{errors?.series?.message}
										</FormErrorMessage>
									</FormControl>
									<FormControl
										isInvalid={
											!!errors?.invoiceNumber?.message
										}
										isRequired
									>
										<FormLabel>Nº da fatura:</FormLabel>
										<Input
											type="number"
											{...register(
												"invoiceNumber" as const
											)}
											isRequired
										/>
										<FormErrorMessage>
											{errors?.invoiceNumber?.message}
										</FormErrorMessage>
									</FormControl>
								</Flex>
								{/* <FormControl
								isInvalid={!!errors?.totalAmount?.message}
								isRequired
							>
								<FormLabel>Total</FormLabel>
								<Input
									type="text"
									{...register("totalAmount")}
								/>
								<FormErrorMessage>
									{errors?.totalAmount?.message}
								</FormErrorMessage>
							</FormControl> */}
							</VStack>
						</form>
					</AlertDialogBody>

					<AlertDialogFooter>
						<Button
							ref={cancelRef}
							onClick={onClose}
							variant={"outline"}
							isDisabled={isLoading}
						>
							Cancel
						</Button>
						<Button
							colorScheme="red"
							onClick={handleSubmit(handleDocInvoiceConversion)}
							ml={3}
							// type="submit"
							isLoading={isLoading}
						>
							Converter
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};

export default InvoiceConversionAlertDialog;
