import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Card,
	CardBody,
	CardHeader,
	Divider,
	Flex,
	Heading,
	List,
	ListItem,
	Stack,
	Text,
	useToast,
} from "@chakra-ui/react";
import { ClientMachineCounts } from "../../api/copyCountsApi";
import { formatMachineName, getErrorMessage } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import TextWithLabel from "../../components/TextWithLabel";
import { FaFileExport } from "react-icons/fa6";
import { useIntegrateCountsMutation } from "../../api/integrationApi";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

interface CountsOverviewListProps {
	data: ClientMachineCounts[];
	title: string;
	color: string;
	isFetching: boolean;
	date?: Date;
}

const CountsOverviewList = ({
	data,
	title,
	color,
	isFetching,
	date = new Date(),
}: CountsOverviewListProps) => {
	const navigate = useNavigate();
	const toast = useToast();

	const [integrateCount, { isLoading }] = useIntegrateCountsMutation();

	function handleIntegration(clientId: number) {
		const promise = integrateCount({
			clientId,
			date,
		});

		toast.promise(promise, {
			loading: {
				title: "A integrarar contagens",
				description: "Aguarde enquanto processamos o seu pedido.",
			},
			success: {
				title: "Integração em processamento. Aguarde.",
			},

			error: (err: Error) => {
				const errorMessage = getErrorMessage(
					err as FetchBaseQueryError | SerializedError
				);
				return {
					title: "Erro na integração de contagens",
					description: errorMessage,
				};
			},
		});
	}

	return (
		<Card borderTop={`6px solid ${color}`} h={"490px"}>
			<CardHeader>
				<Flex alignItems={"center"} justifyContent={"space-between"}>
					<Heading size={"lg"}>{title}</Heading>
					<Text fontSize="sm" color="gray.600">
						({isFetching ? "-" : data.length})
					</Text>
				</Flex>
				<Divider pt={3} />
			</CardHeader>
			<CardBody overflow={"auto"}>
				{isFetching ? (
					<Loading />
				) : (
					<Box>
						<Accordion allowToggle>
							{data.map(({ client, machines }, index) => (
								<AccordionItem key={index + title + client.id}>
									{({ isExpanded }) => (
										<>
											<h2>
												<AccordionButton
													_expanded={{
														bg: "#ECECEC",
													}}
												>
													<Box
														flex="1"
														textAlign="left"
													>
														{client.name}
													</Box>
													<AccordionIcon />
												</AccordionButton>
											</h2>
											<AccordionPanel
												pb={4}
												bg={
													isExpanded ? "#ECECEC" : ""
												}
											>
												<Box
													p={3}
													bg={"white"}
													borderRadius={5}
												>
													<Flex
														pb={5}
														justifyContent={
															"space-between"
														}
														flexWrap={"wrap"}
														gap={5}
													>
														<TextWithLabel
															text={client.email}
															label="Email"
														/>
														<TextWithLabel
															text={
																client.telephone
															}
															label="Telefone"
														/>
													</Flex>
													<Stack gap={1}>
														<Text
															fontSize="sm"
															color="gray.600"
														>
															Máquinas
														</Text>
														<List>
															{machines.map(
																(
																	machine,
																	index
																) => (
																	<ListItem
																		key={`${index}-${machine.id}`}
																		fontWeight={
																			500
																		}
																	>
																		{formatMachineName(
																			machine
																		)}
																	</ListItem>
																)
															)}
														</List>
													</Stack>
													<Flex
														mt={3}
														gap={4}
														align={"center"}
													>
														<Button
															size={"sm"}
															onClick={() =>
																navigate(
																	`/admin/clients/${client.id}/details`
																)
															}
														>
															Detalhes
														</Button>
														<Button
															leftIcon={
																<FaFileExport />
															}
															colorScheme="teal"
															variant="outline"
															size={"sm"}
															onClick={() => {
																handleIntegration(
																	client.id
																);
															}}
															isLoading={
																isLoading
															}
														>
															Integrar
														</Button>
													</Flex>
												</Box>
											</AccordionPanel>
										</>
									)}
								</AccordionItem>
							))}
						</Accordion>
					</Box>
				)}
			</CardBody>
		</Card>
	);
};

export default CountsOverviewList;
