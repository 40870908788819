import { Box, Flex, Heading, Image, Link, Text } from "@chakra-ui/react";
import "../global.css";

const Partners = () => {
	return (
		<Box bg={"white"} py={20} mx={{ base: "16px", lg: "auto" }}>
			<Flex direction={"column"} alignItems={"center"} gap={10}>
				<Heading size={"2xl"}>
					Os nossos{" "}
					<Text
						as={"span"}
						color={"red"}
						textShadow="2px 2px 4px rgb(255 148 148 / 50%)"
					>
						parceiros
					</Text>
				</Heading>
				<Flex justify={"center"}>
					<Link href="https://www.develop.pt">
						<Image
							height={{ base: "80px", lg: "150px" }}
							src="/assets/images/develop.jpeg"
							alt="Introduction Image"
							objectFit={"scale-down"}
						/>
					</Link>
				</Flex>
			</Flex>
		</Box>
	);
};

export default Partners;
