import {
	Box,
	GridItem,
	Step,
	StepDescription,
	StepIcon,
	StepIndicator,
	StepNumber,
	StepSeparator,
	StepStatus,
	StepTitle,
	Stepper,
} from "@chakra-ui/react";
import UserLoggedGridLayout from "../../layouts/UserLoggedGridLayout";
import { customerFormSteps } from "../../features/customerForm/CustomerFormConfig";
import CustomerForm from "../../features/customerForm/CustomerForm";
import { useParams } from "react-router-dom";
import { useGetClientByIdQuery } from "../../api/clientApi";
import Loading from "../../components/Loading";
import { skipToken } from "@reduxjs/toolkit/query";
import { useAppSelector } from "../../app/hooks";

const CustomerFormPage = () => {
	const { id } = useParams();

	const { step, customerData } = useAppSelector(
		(state) => state.customerForm
	);

	const {
		isFetching: clientIsFetching,
		isLoading: clientIsLoading,
		isSuccess: clientIsSuccess,
	} = useGetClientByIdQuery(
		id
			? {
					id: Number(id),
					includeOffices: true,
					includeMachines: true,
					includeUsers: true,
			  }
			: skipToken
	);

	if (clientIsLoading || clientIsFetching) {
		return <Loading fillViewPort />;
	}

	return (
		<UserLoggedGridLayout>
			<GridItem colSpan={{ base: 4, md: 10 }}>
				<Stepper size="lg" index={step} px={20} colorScheme="red">
					{customerFormSteps.map((step, index) => (
						<Step key={index}>
							<StepIndicator>
								<StepStatus
									complete={<StepIcon />}
									incomplete={<StepNumber />}
									active={<StepNumber />}
								/>
							</StepIndicator>

							<Box flexShrink="0">
								<StepTitle>{step.title}</StepTitle>
								<StepDescription>
									{step.description}
								</StepDescription>
							</Box>

							<StepSeparator />
						</Step>
					))}
				</Stepper>
			</GridItem>

			<GridItem colSpan={{ base: 4, md: 10 }} pt={20}>
				{((id && customerData && clientIsSuccess) || !id) && (
					<CustomerForm customerData={customerData} />
				)}
			</GridItem>
		</UserLoggedGridLayout>
	);
};

export default CustomerFormPage;
