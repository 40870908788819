import { apiSlice, providesList } from "./apiSlice";
import { Client } from "./clientApi";
import { MachineContractDetails } from "./contractApi";
import { Machine } from "./machineApi";

export interface CopyCount {
	id: number;
	date: Date;
	blackCount: number;
	colorCount: number;
	blackCopyUnitPrice: number;
	colorCopyUnitPrice: number;
	monthlyFee: number;
	blackCopiesTotalPrice: number;
	colorCopiesTotalPrice: number;
	total: number;
	blackCopiesDiscounted: number | null;
	colorCopiesDiscounted: number | null;
	contractId: number;
	createdAt: Date;
	updatedAt: Date;

	discountsUsed: CopyCountDiscount[];
	machineContractDetails: MachineContractDetails;
}

export interface CopyCountDiscount {
	id: number;
	copyCountId: number;
	discountId: number;
	usedBlackCopies: number;
	usedColorCopies: number;
}

export interface CreateCopyCountPayload {
	date: Date;
	blackCount: number;
	colorCount: number;
	machineContractDetailsId: number;
}

export interface CreateCopyCountAdminPayload {
	date: Date;
	blackCount: number;
	colorCount: number;
	blackCopyUnitPrice: number;
	colorCopyUnitPrice: number;
	blackCopiesTotalPrice: number;
	colorCopiesTotalPrice: number;
	total: number;
	machineContractDetailsId: number;
}

export interface UpdateCopyCountPayload {
	id: number;
	date: Date;
	blackCount: number;
	colorCount: number;
	machineContractDetailsId: number;
}

export type CopyCountWithDiff = CopyCount & {
	blackDiff: number;
	colorDiff: number;
};

export interface ClientMachineCounts {
	client: Client;
	machines: Machine[];
}

export const copyCountsApi = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		getAllCountsByContractIdAndMachineId: build.query<
			CopyCount[],
			{ contractId: number; machineId: number }
		>({
			query: ({ contractId, machineId }) =>
				`/counts/contract/${contractId}/machine/${machineId}/`,
			providesTags: (result) => providesList(result, "CopyCount"),
		}),

		getAllCountsByContractIdAndMachineIdYearly: build.query<
			CopyCountWithDiff[],
			{ contractId: number; machineId: number; year: number }
		>({
			query: ({ contractId, machineId, year }) =>
				`/counts/contract/${contractId}/machine/${machineId}/${year}`,
			providesTags: (result) => providesList(result, "CopyCount"),
		}),

		getLastCountByContractIdAndMachineId: build.query<
			CopyCount,
			{ contractId: number; machineId: number }
		>({
			query: ({ contractId, machineId }) =>
				`/counts/contract/${contractId}/machine/${machineId}/lastcount`,
			providesTags: (_result, _error, { contractId, machineId }) => [
				{ type: "Contract", id: contractId },
				{ type: "Machine", id: machineId },
				{ type: "CopyCount", id: "LIST" },
			],
		}),

		getMissingCounts: build.query<
			ClientMachineCounts[],
			string | undefined
		>({
			query: (date) => {
				return date
					? `/counts/missing?${new URLSearchParams({
							date: new Date(date).toISOString(),
					  })}`
					: `/counts/missing`;
			},
			providesTags: (result) =>
				result ? [{ type: "MissingCounts", id: "LIST" }] : [],
		}),

		getMissingCountsByClientId: build.query<
			ClientMachineCounts,
			{ clientId: number }
		>({
			query: ({ clientId }) => `/counts/client/${clientId}/missing`,
			providesTags: (result) =>
				result ? [{ type: "MissingCounts", id: "LIST" }] : [],
		}),

		getSubmittedCounts: build.query<
			ClientMachineCounts[],
			string | undefined
		>({
			query: (date) => {
				return date
					? `/counts/submitted?${new URLSearchParams({
							date: new Date(date).toISOString(),
					  })}`
					: `/counts/submitted`;
			},
			providesTags: (result) =>
				result ? [{ type: "MissingCounts", id: "LIST" }] : [],
		}),
		getCountsToExport: build.query<
			ClientMachineCounts[],
			string | undefined
		>({
			query: (date) => {
				return date
					? `/counts/toExport?${new URLSearchParams({
							date: new Date(date).toISOString(),
					  })}`
					: `/counts/toExport`;
			},
			providesTags: (result) =>
				result ? [{ type: "MissingCounts", id: "LIST" }] : [],
		}),

		createCopyCount: build.mutation<CopyCount, CreateCopyCountPayload>({
			query: (newCopyCount) => ({
				url: "/counts",
				method: "POST",
				body: newCopyCount,
			}),
			invalidatesTags: (result) => [
				{ type: "CopyCount", id: "LIST" },
				{ type: "MissingCounts", id: "LIST" },
				{
					type: "CountsBilling",
					id: result ? result.contractId : "LIST",
				},
			],
		}),

		createCopyCountAdmin: build.mutation<
			CopyCount,
			CreateCopyCountAdminPayload
		>({
			query: (newCopyCount) => ({
				url: "/counts/admin",
				method: "POST",
				body: newCopyCount,
			}),
			invalidatesTags: [{ type: "CopyCount", id: "LIST" }],
		}),

		updateCopyCount: build.mutation<CopyCount[], UpdateCopyCountPayload>({
			query: (newCopyCount) => ({
				url: "/counts",
				method: "PUT",
				body: newCopyCount,
			}),
			invalidatesTags: [
				{ type: "CopyCount", id: "LIST" },
				{ type: "CountsBilling", id: "LIST" },
			],
		}),
	}),
});

// export react hook
export const {
	useCreateCopyCountMutation,
	useCreateCopyCountAdminMutation,
	useGetLastCountByContractIdAndMachineIdQuery,
	useLazyGetAllCountsByContractIdAndMachineIdQuery,
	useUpdateCopyCountMutation,
	useLazyGetAllCountsByContractIdAndMachineIdYearlyQuery,
	useGetAllCountsByContractIdAndMachineIdYearlyQuery,
	useGetMissingCountsByClientIdQuery,
	useGetMissingCountsQuery,
	useGetSubmittedCountsQuery,
	useGetCountsToExportQuery,
} = copyCountsApi;
