import { apiSlice } from "./apiSlice";
import { Client } from "./clientApi";
import { Machine } from "./machineApi";

export enum ExportStatus {
	Pending = "Pending",
	Billed = "Billed",
	Rejected = "Rejected",
	Empty = "Empty",
}

interface StatusCount {
	Billed: number;
	Rejected: number;
	Pending: number;
	Empty: number;
}

export interface ExportCount {
	id: number;
	copyCountId: number;
	exportDate: Date;
	// integrationDate?: Date | null;
	status: ExportStatus;
	documentType: string;
	number: number;
	series: string;
	machineContractDetailsId?: number;

	machine: Machine;
	invoice?: Invoice;
}

interface Invoice {
	id: number;
	invoiceDate: Date;
	documentType: string;
	series: string;
	number: string;
	totalAmount: number;
}

interface MonthlyCopyCountsIntegrations {
	statusCount: StatusCount;
	exports: {
		client: Client;
		exportInfo: ExportCount[];
	}[];
}

export const integrationApi = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		getMonthlyExportedCounts: build.query<
			MonthlyCopyCountsIntegrations,
			string | undefined
		>({
			query: (date) => {
				return date
					? `/integration/sage/copycounts/monthlyIntegrations?${new URLSearchParams(
							{
								date: new Date(date).toISOString(),
							}
					  )}`
					: `/integration/sage/copycounts/monthlyIntegrations`;
			},
			providesTags: (result) =>
				result ? [{ type: "MonthlyCountExport", id: "LIST" }] : [],
		}),
		updateCountExportStatus: build.mutation<
			{ message?: string },
			{
				docNumber?: string;
				docType?: string;
				docSeries?: string;
				status: ExportStatus;
				copyCountExportIds?: number[];
				invoiceNumber?: string;
				invoiceDate?: string;
				// totalAmount?: number;
				invoiceType?: string;
				invoiceSeries?: string;
			}
		>({
			query: (args) => ({
				url: "/integration/sage/copycounts/updateStatus",
				method: "PUT",
				body: {
					...args,
				},
			}),
			invalidatesTags: (result) => {
				return result
					? [{ type: "MonthlyCountExport", id: "LIST" }]
					: [];
			},
		}),
		integrateCounts: build.mutation<
			void,
			{ clientId: number; date: Date }
		>({
			query: ({ clientId, date }) => ({
				url: "/integration/sage/export/counts",
				method: "POST",
				body: { clientId, date },
			}),
		}),
	}),
});

// export react hook
export const {
	useGetMonthlyExportedCountsQuery,
	useUpdateCountExportStatusMutation,
	useIntegrateCountsMutation,
} = integrationApi;
