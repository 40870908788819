import {
	Flex,
	Box,
	FormControl,
	FormLabel,
	Input,
	Stack,
	Button,
	Heading,
	useColorModeValue,
	Alert,
	AlertIcon,
	AlertTitle,
	useToast,
	Link,
	Image,
} from "@chakra-ui/react";
import { useState, useEffect, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "../api/authApi";
import { Role } from "../utils/constants";
import { getErrorMessage, isAdmin, isClient } from "../utils/helpers";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { decodeToken } from "../utils/tokenUtils";
import { useAppSelector } from "../app/hooks";

const LoginPage = () => {
	const navigate = useNavigate();
	const toast = useToast();

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");

	const { isAuthenticated, role, companies } = useAppSelector(
		(state) => state.auth
	);

	const [login, { isError }] = useLoginMutation();

	useEffect(() => {
		if (isAuthenticated) {
			if (role === Role.User) navigate("/client");
			else if (role === Role.Admin) navigate("/admin");
		}
		if (companies) {
			if (role === Role.User) navigate("/select-company");
		}
	}, [isAuthenticated, navigate, role]);

	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const promise = login({ email, password }).unwrap();
		toast.promise(promise, {
			loading: {
				title: "A fazer login",
				description: "Aguarde enquanto processamos o seu pedido.",
			},
			success: {
				title: "Login efetuado",
			},
			error: (err: Error) => {
				const errorMessage = getErrorMessage(
					err as FetchBaseQueryError | SerializedError
				);

				setError(errorMessage);
				return {
					title: "Erro ao efetuar o login",
					description: errorMessage,
				};
			},
		});

		promise
			.then((data) => {
				const decoded_data = decodeToken(
					data.accessToken ?? data.tmpToken ?? ""
				);

				if (!decoded_data) return;

				const role = decoded_data.role;
				if (isAdmin(role)) {
					navigate("/admin");
				} else if (isClient(role)) {
					if ("tmpToken" in data && "companies" in data) {
						navigate("/select-company");
					} else navigate("/client");
				}
			})
			.catch(() => {});
	};

	return (
		<Flex
			minH={"100vh"}
			align={"center"}
			justify={"center"}
			bg={useColorModeValue("gray.50", "gray.800")}
			direction={"column"}
		>
			<Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
				<Stack align={"center"}>
					<Link href="/">
						<Image
							w={{ base: "320px", md: "600px" }}
							src="/assets/images/logo.png"
							alt="Assisteprint Logo"
							paddingBottom={"40px"}
						/>
					</Link>
					<Heading fontSize={"3xl"}>
						Inicie sessão na sua conta
					</Heading>
					<Alert status="warning" mt={"20px"}>
						<AlertIcon />
						<AlertTitle>
							Se já é cliente, recebeu um email de
							&quot;noreply@assisteprint.com&quot; com as
							instruções para iniciar sessão.
						</AlertTitle>
					</Alert>
					{isError && error && (
						<Alert status="error" mt={"20px"}>
							<AlertIcon />
							<AlertTitle>{error}</AlertTitle>
						</Alert>
					)}
				</Stack>
				<Box
					rounded={"lg"}
					bg={useColorModeValue("white", "gray.700")}
					boxShadow={"lg"}
					p={8}
				>
					<form onSubmit={handleSubmit}>
						<Stack spacing={4}>
							<FormControl id="email" isRequired>
								<FormLabel>Email</FormLabel>
								<Input
									isInvalid={isError}
									type="email"
									onChange={(e) => setEmail(e.target.value)}
								/>
							</FormControl>
							<FormControl id="password" isRequired>
								<FormLabel>Password</FormLabel>
								<Input
									isInvalid={isError}
									type="password"
									autoComplete="true"
									onChange={(e) =>
										setPassword(e.target.value)
									}
								/>
							</FormControl>
							<Stack spacing={5}>
								<Button type="submit">Iniciar sessão</Button>
								<Stack direction={"column"} align={"center"}>
									{/* <Checkbox>Remember me</Checkbox> */}
									<Link href="/password-reset">
										<Button variant={"ghost"} size={"sm"}>
											Esqueceu-se da password?
										</Button>
									</Link>
								</Stack>
							</Stack>
						</Stack>
					</form>
				</Box>
			</Stack>
			<Button
				variant={"ghost"}
				onClick={() => navigate("/")}
				size={"xs"}
			>
				Voltar à página inicial
			</Button>
		</Flex>
	);
};

export default LoginPage;
