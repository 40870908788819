import { apiSlice } from "./apiSlice";

type ApiLoginResponse = {
	accessToken?: string;
	refreshToken?: string;
	tmpToken?: string;
	companies?: {
		id: number;
		name: string;
	}[];
};

type ApiLoginRequest = {
	email: string;
	password: string;
};

type ApiRegisterRequest = {
	name: string;
	email: string;
	password: string;
	token: string;
};

type ResetEmailRequest = {
	email: string;
};

type ResetRequest = {
	token: string;
	password: string;
};

export const authApi = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		register: build.mutation<ApiLoginResponse, ApiRegisterRequest>({
			query: ({ name, email, password, token }) => ({
				url: "users/register",
				method: "POST",
				body: { name, email, password, token },
			}),
		}),
		login: build.mutation<ApiLoginResponse, ApiLoginRequest>({
			query: ({ email, password }) => ({
				url: "auth/login",
				method: "POST",
				body: { email, password },
			}),
		}),
		selectCompany: build.mutation<
			ApiLoginResponse,
			{ selectedCompanyId: number }
		>({
			query: ({ selectedCompanyId }) => ({
				url: "auth/select-company",
				method: "POST",
				body: { selectedCompanyId },
			}),
		}),
		logout: build.mutation<null, void>({
			query: () => ({
				url: "auth/logout",
				method: "POST",
			}),
		}),
		sendResetEmail: build.mutation<null, ResetEmailRequest>({
			query: ({ email }) => ({
				url: "auth/password-reset",
				method: "POST",
				body: { email },
			}),
		}),
		resetPassword: build.mutation<null, ResetRequest>({
			query: ({ token, password }) => ({
				url: "auth/password-reset",
				method: "PUT",
				body: { token, password },
			}),
		}),
	}),
});

// export react hook
export const {
	useLoginMutation,
	useLogoutMutation,
	useRegisterMutation,
	useSendResetEmailMutation,
	useResetPasswordMutation,
	useSelectCompanyMutation,
} = authApi;
