import {
	Button,
	Flex,
	Heading,
	useColorModeValue,
	useToast,
} from "@chakra-ui/react";
import { useAppSelector } from "../app/hooks";
import { useSelectCompanyMutation } from "../api/authApi";
import { useNavigate } from "react-router-dom";
import { decodeToken } from "../utils/tokenUtils";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetAuth } from "../features/authSlice";

const SelectCompany = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const toast = useToast();
	const { companies } = useAppSelector((state) => state.auth);

	const [selectCompany] = useSelectCompanyMutation();

	const handleSelection = (selectedCompanyId: number) => {
		const promise = selectCompany({ selectedCompanyId }).unwrap();
		toast.promise(promise, {
			loading: {
				title: "A fazer login",
				description: "Aguarde enquanto processamos o seu pedido.",
			},
			success: {
				title: "Login efetuado",
			},
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			error: (_err: Error) => {
				return {
					title: "Erro ao completar o login",
					description: "Tente iniciar sessão novamente",
				};
			},
		});

		promise
			.then((data) => {
				const decoded_data = decodeToken(data.accessToken ?? "");
				if (!decoded_data) return;
				navigate("/client");
			})
			.catch(() => {});
	};

	const handleLogout = () => {
		dispatch(resetAuth());
		navigate("/");
	};

	useEffect(() => {
		if (!companies) navigate("/login");
	}, [companies]);

	return (
		<Flex
			minH={"100vh"}
			align={"center"}
			justify={"center"}
			direction={"column"}
			gap={"50px"}
			bg={useColorModeValue("gray.50", "gray.800")}
		>
			<Heading>Selecione a empresa</Heading>
			<Flex gap={"50px"}>
				{companies?.map((c, index) => (
					<Button
						key={index}
						variant={"outline"}
						colorScheme="red"
						onClick={() => handleSelection(c.id)}
					>
						{c.name}
					</Button>
				))}
			</Flex>
			<Button variant={"ghost"} mt={10} onClick={() => handleLogout()}>
				Terminar Sessão
			</Button>
		</Flex>
	);
};

export default SelectCompany;
