import { Flex, Button, useToast } from "@chakra-ui/react";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError, skipToken } from "@reduxjs/toolkit/query";
import {
	CopyCount,
	useCreateCopyCountMutation,
	useGetAllCountsByContractIdAndMachineIdYearlyQuery,
	useGetLastCountByContractIdAndMachineIdQuery,
	useUpdateCopyCountMutation,
} from "../../api/copyCountsApi";
import { getErrorMessage, isAdmin, isClient } from "../../utils/helpers";
import { checkSubmitAvailability, setEditing } from "./countsSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useGetMachineActiveContractAndDetailsByMachineIdQuery } from "../../api/machineApi";
import { useEffect } from "react";

interface CountActionsProps {
	machineId: number | undefined;
	year: number | undefined;
	contractId: number | undefined;
}

const CountActions = ({ machineId, year, contractId }: CountActionsProps) => {
	const dispatch = useAppDispatch();
	const toast = useToast();

	const { role } = useAppSelector((state) => state.auth);
	const {
		editingId,
		newCountData,
		isSubmitAvailable,
		updateCountData,
		isLoading,
	} = useAppSelector((state) => state.counts);

	const [createCopyCount] = useCreateCopyCountMutation();
	const [updateCopyCount] = useUpdateCopyCountMutation();

	const { data: machineContractDetails } =
		useGetMachineActiveContractAndDetailsByMachineIdQuery(
			machineId ?? skipToken
		);

	const { data: lastCounts } =
		useGetAllCountsByContractIdAndMachineIdYearlyQuery(
			contractId && machineId && year && isAdmin(role)
				? {
						contractId: contractId,
						machineId: machineId,
						year: year,
				  }
				: skipToken,
			{
				refetchOnMountOrArgChange: true,
			}
		);
	const { data: clientLastCount } =
		useGetLastCountByContractIdAndMachineIdQuery(
			contractId && machineId && isClient(role)
				? { contractId: contractId, machineId: machineId }
				: skipToken,
			{ refetchOnMountOrArgChange: true }
		);

	useEffect(() => {
		dispatch(
			checkSubmitAvailability({
				isAdmin: isAdmin(role),
				year,
				contract: machineContractDetails?.contract,
				lastCounts: lastCounts
					? lastCounts
					: clientLastCount
					? [clientLastCount]
					: [],
			})
		);
	}, [
		year,
		lastCounts,
		clientLastCount,
		machineContractDetails,
		role,
		dispatch,
	]);

	const handleSubmit = async () => {
		const machineContractDetailsId = machineContractDetails?.id;
		if (!machineContractDetailsId) {
			return;
		}

		let promise: Promise<CopyCount[]>;
		if (editingId && updateCountData && lastCounts) {
			if (lastCounts.length === 0) {
				return;
			}

			const countToEdit = lastCounts.find(
				(count) => count.id === editingId
			);

			if (!countToEdit) return;

			const payloadDate = new Date(updateCountData.submitDate);

			const updateCopyCountPayload = {
				id: countToEdit.id,
				date: new Date(
					Date.UTC(
						payloadDate.getUTCFullYear(),
						payloadDate.getUTCMonth(),
						payloadDate.getUTCDate(),
						payloadDate.getUTCHours(),
						payloadDate.getUTCMinutes(),
						payloadDate.getUTCSeconds()
					)
				),
				blackCount: updateCountData.blackCount,
				colorCount: updateCountData.colorCount,
				machineContractDetailsId,
			};

			promise = updateCopyCount(updateCopyCountPayload).unwrap();
		} else {
			const payloadDate = new Date(newCountData.submitDate);
			const newCopyCountPayload = {
				date: new Date(
					Date.UTC(
						payloadDate.getUTCFullYear(),
						payloadDate.getUTCMonth(),
						payloadDate.getUTCDate(),
						payloadDate.getUTCHours(),
						payloadDate.getUTCMinutes(),
						payloadDate.getUTCSeconds()
					)
				),
				blackCount: newCountData.blackCount,
				colorCount: newCountData.colorCount,
				machineContractDetailsId,
			};

			promise = createCopyCount(newCopyCountPayload)
				.unwrap()
				.then((result) => [result]);
		}

		toast.promise(promise, {
			loading: {
				title: "A submeter contagem",
				description: "Aguarde enquanto processamos o seu pedido.",
			},
			success: {
				title: "Contagem submetida",
				description:
					"A contagem das cópias foi submetida com sucesso.",
			},
			error: (err: Error) => {
				const errorMessage = getErrorMessage(
					err as FetchBaseQueryError | SerializedError
				);

				return {
					title: "Erro na submissão de contagens",
					description: errorMessage,
				};
			},
		});
	};

	return (
		<Flex justifyContent={"right"}>
			{editingId && (
				<Button
					m={6}
					onClick={() => dispatch(setEditing(null))}
					isDisabled={isLoading}
					variant={"outline"}
				>
					Cancelar
				</Button>
			)}

			{(isSubmitAvailable || editingId) && (
				<Flex justifyContent={"flex-end"}>
					<Button
						m={6}
						onClick={handleSubmit}
						isDisabled={isLoading}
					>
						Submeter
					</Button>
				</Flex>
			)}
		</Flex>
	);
};

export default CountActions;
