import { Box, Grid } from "@chakra-ui/react";
import Navbar from "../components/Navbar";

interface UserLoggedGridLayoutProps {
	children: React.ReactNode;
}

const UserLoggedGridLayout = ({ children }: UserLoggedGridLayoutProps) => {
	return (
		<Box minH="100vh" bg={"white"}>
			<Navbar />
			<Grid
				templateColumns={{
					base: "repeat(4, 1fr)",
					md: "repeat(10, 1fr)",
				}}
				gridAutoRows={"min-content"}
				gap={"20px"}
				minH="100vh"
				bg={"white"}
				ml={{ base: 0, md: 64 }}
				mr={{ base: 0, md: 4 }}
				my={{ base: 0, md: 4 }}
				mx={{ base: 3 }}
				mt={{ base: 3 }}
			>
				{/* Content */}
				{children}
			</Grid>
		</Box>
	);
};

export default UserLoggedGridLayout;
