import {
	Container,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	VStack,
} from "@chakra-ui/react";
import { useGetAllClientsQuery } from "../../api/clientApi";
import { useFormContext } from "react-hook-form";
import Loading from "../../components/Loading";
import Select from "react-select";
import { ContractFormType } from "./ContractValidationSchemas";
import { billingTypes } from "./ContractFormConfig";
import { BillingType } from "../../api/contractApi";

const ContractStep0 = () => {
	const { data: clients, isLoading, isFetching } = useGetAllClientsQuery();

	const {
		register,
		setValue,
		getValues,
		formState: { errors },
	} = useFormContext<ContractFormType>();

	if (isLoading || isFetching) {
		return <Loading fillViewPort />;
	}

	return (
		<Container maxW={"70%"}>
			<VStack spacing={4} align="flex-start">
				<FormControl
					isInvalid={!!errors?.contract?.clientId?.message}
					isRequired
				>
					<FormLabel>Cliente</FormLabel>
					<Select
						{...register(`contract.clientId` as const)}
						options={
							clients?.map((client) => ({
								label: client.name,
								value: client.id.toString(),
							})) || []
						}
						value={(
							clients?.map((client) => ({
								label: client.name,
								value: client.id.toString(),
							})) || []
						).find(
							(client) =>
								client.value ===
								getValues(`contract.clientId`).toString()
						)}
						onChange={(option) =>
							setValue(
								`contract.clientId`,
								option?.value || "",
								{
									shouldValidate: true,
								}
							)
						}
						placeholder={"Escolha um cliente"}
					/>
					<FormErrorMessage>
						{errors?.contract?.clientId?.message}
					</FormErrorMessage>
				</FormControl>

				<Flex gap={4} w={"100%"}>
					<FormControl
						isInvalid={!!errors?.contract?.startDate?.message}
						isRequired
					>
						<FormLabel>Data de início</FormLabel>
						<Input
							type="date"
							{...register("contract.startDate")}
						/>
						<FormErrorMessage>
							{errors?.contract?.startDate?.message}
						</FormErrorMessage>
					</FormControl>

					<FormControl
						isInvalid={!!errors?.contract?.endDate?.message}
						isRequired
					>
						<FormLabel>Data de fim</FormLabel>
						<Input type="date" {...register("contract.endDate")} />
						<FormErrorMessage>
							{errors?.contract?.endDate?.message}
						</FormErrorMessage>
					</FormControl>
				</Flex>

				<FormControl
					isInvalid={!!errors?.contract?.clientId?.message}
					isRequired
				>
					<FormLabel>Tipo de faturação</FormLabel>
					<Select
						{...register(`contract.billingType` as const)}
						options={billingTypes}
						value={billingTypes.find(
							(billingType) =>
								billingType.value ===
								getValues(`contract.billingType`)
						)}
						onChange={(option) => {
							setValue(
								`contract.billingType`,
								option!.value as BillingType,
								{
									shouldValidate: true,
								}
							);
						}}
						placeholder={"Escolha um tipo de faturação"}
					/>
					<FormErrorMessage>
						{errors?.contract?.clientId?.message}
					</FormErrorMessage>
				</FormControl>
			</VStack>
		</Container>
	);
};

export default ContractStep0;
