import { Spinner, Stack } from "@chakra-ui/react";

interface LoadingProps {
	fillViewPort?: boolean;
}

const Loading = ({ fillViewPort = false }: LoadingProps) => {
	return (
		<Stack
			direction="row"
			justifyContent="center"
			alignItems="center"
			sx={
				fillViewPort
					? { width: "100%", height: "100vh" }
					: { width: "100%", height: "100%" }
			}
		>
			<Spinner />
		</Stack>
	);
};

export default Loading;
