import { useGetActiveContractsCountStatsQuery } from "../../api/statsApi";
import { FiFile } from "react-icons/fi";
import CountStat from "../../components/CountStatCard";

const ContractsStat = () => {
	const { data, isLoading } = useGetActiveContractsCountStatsQuery();

	return (
		<CountStat
			label={"Contratos"}
			result={{
				data,
				isLoading,
			}}
			Icon={FiFile}
		/>
	);
};

export default ContractsStat;
