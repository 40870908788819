import {
	CustomerFormSchema,
	CustomerFormType,
} from "./CustomerValidationSchemas";
import CustomerStep0 from "./CustomerStep0";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormNavigation from "../../components/FormNavigation";
import { customerFormSteps } from "./CustomerFormConfig";
import CustomerStep1 from "./CustomerStep1";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { getErrorMessage } from "../../utils/helpers";
import { SerializedError } from "@reduxjs/toolkit";
import { useToast } from "@chakra-ui/react";
import {
	useCreateClientMutation,
	useUpdateClientByIdMutation,
} from "../../api/clientApi";
import CustomerStep2 from "./CustomerStep2";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import CustomerStep3 from "./CustomerStep3";
import { useDispatch } from "react-redux";
import { setStep } from "../customerFormSlice";
import { useAppSelector } from "../../app/hooks";

interface CustomerFormProps {
	customerData: CustomerFormType;
}

const CustomerForm = ({ customerData }: CustomerFormProps) => {
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const toast = useToast();

	const { step, isStepValid } = useAppSelector(
		(state) => state.customerForm
	);

	const [createClient, { isLoading: isCreateLoading }] =
		useCreateClientMutation();
	const [updateClient, { isLoading: isUpdateLoading }] =
		useUpdateClientByIdMutation();

	const methods = useForm<CustomerFormType>({
		defaultValues: customerData,
		mode: "onBlur",
		resolver: yupResolver(CustomerFormSchema),
	});

	const { offices, users } = methods.getValues();

	const handleStepSubmit = async () => {
		const fields = customerFormSteps[step].fields;

		let dynamicFields;
		switch (step) {
			case 0:
				dynamicFields = fields;
				break;
			case 1:
				dynamicFields = (offices || []).flatMap((_, officeIndex) =>
					fields.map((field) =>
						field.replace("*", officeIndex.toString())
					)
				);
				break;
			case 2:
				dynamicFields = (offices || []).flatMap(
					(office, officeIndex) =>
						office.machines?.flatMap((_, machineIndex) =>
							fields.map((field) =>
								field
									.replace("**", officeIndex.toString())
									.replace("*", machineIndex.toString())
							)
						) || []
				);
				break;
			case 3:
				dynamicFields = (users || []).flatMap((_, index) =>
					fields.map((field) => field.replace("*", index.toString()))
				);
				break;
			case 4:
				return;
		}

		const output = await methods.trigger(
			dynamicFields as Array<keyof CustomerFormType>,
			{
				shouldFocus: true,
			}
		);

		if (!output) {
			return;
		}

		if (step < customerFormSteps.length - 1) {
			dispatch(setStep(step + 1));
		}
	};

	const onSubmit = async () => {
		let promise;
		try {
			const client = {
				...methods.getValues(),
				id: Number(customerData.id) || 0,
			};

			if (id) promise = updateClient(client).unwrap();
			else promise = createClient(client).unwrap();

			toast.promise(promise, {
				loading: {
					title: "A submeter o formulário",
					description: "Aguarde enquanto processamos o seu pedido.",
				},
				success: {
					title: id
						? "Cliente atualizado com sucesso"
						: "Cliente criado com sucesso",
				},
				error: (err: Error) => {
					const errorMessage = getErrorMessage(
						err as FetchBaseQueryError | SerializedError
					);
					return {
						title: id
							? "Erro ao atualizar o Cliente"
							: "Erro na criação do Cliente",
						description: errorMessage,
					};
				},
			});
			promise.then((result) => result && navigate("/admin/clients"));
		} catch (error) {
			console.debug("Submission error:", error);
		}
	};

	if (isCreateLoading || isUpdateLoading) {
		return <Loading fillViewPort />;
	}

	return (
		<>
			<FormProvider {...methods}>
				<form>
					{step === 0 && <CustomerStep0 />}
					{step === 1 && <CustomerStep1 />}
					{step === 2 && <CustomerStep2 />}
					{step === 3 && <CustomerStep3 />}
					<FormNavigation
						maxSteps={customerFormSteps.length}
						currentIndex={step}
						isStepValid={isStepValid}
						onClickNext={handleStepSubmit}
						onClickBack={() => dispatch(setStep(step - 1))}
						onSubmit={methods.handleSubmit(onSubmit)}
					/>
				</form>
			</FormProvider>
		</>
	);
};

export default CustomerForm;
