import {
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	Stack,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	Text,
	IconButton,
	Flex,
} from "@chakra-ui/react";
import { FiEdit3 } from "react-icons/fi";
import {
	useGetAllCountsByContractIdAndMachineIdYearlyQuery,
	useGetLastCountByContractIdAndMachineIdQuery,
} from "../../api/copyCountsApi";
import { useAppDispatch } from "../../app/store";
import { formatDateMonth } from "../../utils/dateUtils";
import { isAdmin, isClient } from "../../utils/helpers";
import { setBlackCount, setEditing, setColorCount } from "./countsSlice";
import { useAppSelector } from "../../app/hooks";
import AddNewCount from "./AddNewCount";
import { skipToken } from "@reduxjs/toolkit/query";
import Loading from "../../components/Loading";

interface CountsTableProps {
	contractId: number | undefined;
	machineId: number | undefined;
	year: number | undefined;
}

const CountsTable = ({ contractId, machineId, year }: CountsTableProps) => {
	const dispatch = useAppDispatch();

	const { role } = useAppSelector((state) => state.auth);
	const {
		updateCountData,
		editingId,
		contractNotFoundError,
		isContractInactive,
	} = useAppSelector((state) => state.counts);

	const {
		data: clientLastCount,
		isSuccess: isClientSuccess,
		isLoading: isClientCountsLoading,
	} = useGetLastCountByContractIdAndMachineIdQuery(
		contractId && machineId && isClient(role)
			? { contractId: contractId, machineId: machineId }
			: skipToken,
		{ refetchOnMountOrArgChange: true }
	);
	const {
		data: adminLastCounts = [],
		isSuccess: isAdminSuccess,
		isLoading: isAdminCountsLoading,
	} = useGetAllCountsByContractIdAndMachineIdYearlyQuery(
		contractId && machineId && year && isAdmin(role)
			? {
					contractId: contractId,
					machineId: machineId,
					year: year,
			  }
			: skipToken,
		{
			refetchOnMountOrArgChange: true,
		}
	);

	const lastCounts = isAdmin(role)
		? adminLastCounts
		: clientLastCount
		? [clientLastCount]
		: [];
	const isSuccess = isAdmin(role) ? isAdminSuccess : isClientSuccess;
	const isLoading = isAdmin(role)
		? isAdminCountsLoading
		: isClientCountsLoading;

	if (!machineId || !year || contractNotFoundError || isContractInactive)
		return null;

	return (
		<TableContainer mt={8}>
			<Table
				variant={
					lastCounts.length > 0 && !editingId ? "striped" : "simple"
				}
			>
				<Thead>
					<Tr>
						<Th>Mês</Th>
						<Th>
							Copias
							<br />
							Preto
						</Th>
						{isAdmin(role) && (
							<Th>
								Preco Copia
								<br />
								Preto (€)
							</Th>
						)}
						{isAdmin(role) && (
							<Th>
								Preco Total
								<br />
								Copias Preto (€)
							</Th>
						)}
						<Th>
							Copias Preto <br />
							Descontadas
						</Th>
						<Th>
							Copias
							<br />
							Cor
						</Th>
						{isAdmin(role) && (
							<Th>
								Preco Copia
								<br />
								Cor (€)
							</Th>
						)}
						{isAdmin(role) && (
							<Th>
								Preco Total
								<br />
								Copias Cor (€)
							</Th>
						)}
						<Th>
							Copias Cor <br />
							Descontadas
						</Th>
						<Th>Total (€)</Th>
					</Tr>
				</Thead>
				<Tbody>
					{/* Last counts fetched from the api */}
					{isSuccess &&
						lastCounts.map((count, index) => (
							<Tr key={count.id}>
								<Td>
									{formatDateMonth(new Date(count.date))}
								</Td>
								<Td>
									<Stack
										direction={"row"}
										alignItems={"center"}
									>
										{editingId &&
										count.id === editingId ? (
											<NumberInput
												defaultValue={count.blackCount}
												min={0}
												max={10000000}
												w={"120px"}
												onChange={(
													_,
													valueAsNumber
												) => {
													dispatch(
														setBlackCount(
															valueAsNumber
														)
													);
												}}
											>
												<NumberInputField />
												<NumberInputStepper>
													<NumberIncrementStepper />
													<NumberDecrementStepper />
												</NumberInputStepper>
											</NumberInput>
										) : (
											<>
												<Flex>
													{count.blackCount}&nbsp;
													{isAdmin(role) && (
														<Text
															fontSize="xs"
															color="gray.500"
														>
															(+
															{count.blackDiff})
														</Text>
													)}
												</Flex>
												{isAdmin(role) &&
													index ===
														lastCounts.length -
															1 && (
														<IconButton
															aria-label="Editar contagem"
															icon={<FiEdit3 />}
															variant="ghost"
															colorScheme="gray"
															size={"sm"}
															onClick={() =>
																dispatch(
																	setEditing(
																		count
																	)
																)
															}
														/>
													)}
											</>
										)}
									</Stack>
								</Td>
								{isAdmin(role) && (
									<Td>{count.blackCopyUnitPrice} €</Td>
								)}
								{isAdmin(role) && (
									<Td>
										{count.blackCopiesTotalPrice.toFixed(
											2
										)}{" "}
										€
									</Td>
								)}
								<Td>{count.blackCopiesDiscounted}</Td>
								<Td>
									<Stack
										direction={"row"}
										alignItems={"center"}
									>
										{editingId &&
										count.id === editingId ? (
											<NumberInput
												defaultValue={count.colorCount}
												min={0}
												max={10000000}
												w={"120px"}
												onChange={(
													_,
													valueAsNumber
												) => {
													dispatch(
														setColorCount(
															valueAsNumber
														)
													);
												}}
											>
												<NumberInputField />
												<NumberInputStepper>
													<NumberIncrementStepper />
													<NumberDecrementStepper />
												</NumberInputStepper>
											</NumberInput>
										) : (
											<>
												<Flex>
													{count.colorCount}&nbsp;
													{isAdmin(role) && (
														<Text
															fontSize="xs"
															color="gray.500"
														>
															(+
															{count.colorDiff})
														</Text>
													)}
												</Flex>
												{/* Cant update because the  */}
												{isAdmin(role) &&
													index ===
														lastCounts.length -
															1 && (
														<IconButton
															aria-label="Editar contagem"
															icon={<FiEdit3 />}
															variant="ghost"
															colorScheme="gray"
															size={"sm"}
															onClick={() =>
																dispatch(
																	setEditing(
																		count
																	)
																)
															}
														/>
													)}
											</>
										)}
									</Stack>
								</Td>
								{isAdmin(role) && (
									<Td>{count.colorCopyUnitPrice} €</Td>
								)}
								{isAdmin(role) && (
									<Td>
										{count.colorCopiesTotalPrice.toFixed(
											2
										)}{" "}
										€
									</Td>
								)}
								<Td>{count.colorCopiesDiscounted}</Td>
								<Td>
									{editingId &&
									count.id === editingId &&
									updateCountData
										? updateCountData.total.toFixed(2)
										: count.total.toFixed(2)}{" "}
									€
								</Td>
							</Tr>
						))}

					{/* Total counts row for admin only */}
					{isAdmin(role) && lastCounts.length !== 0 && (
						<Tr
							fontWeight="bold"
							bg="gray.200"
							borderTop="2px solid"
							borderColor="gray.300"
						>
							<Td>Total</Td>
							<Td>
								{lastCounts.reduce((partialSum, copyCount) => {
									const blackDiff = copyCount.blackDiff;
									return (
										partialSum +
										(blackDiff !== undefined
											? blackDiff
											: 0)
									);
								}, 0)}
							</Td>
							<Td></Td>
							<Td></Td>
							<Td></Td>
							<Td>
								{lastCounts.reduce((partialSum, copyCount) => {
									const colorDiff = copyCount.colorCount;
									return (
										partialSum +
										(colorDiff !== undefined
											? colorDiff
											: 0)
									);
								}, 0)}
							</Td>
							<Td></Td>
							<Td></Td>
							<Td></Td>
							<Td>
								{lastCounts
									.reduce(
										(partialSum, a) =>
											partialSum + a.total,
										0
									)
									.toFixed(2)}{" "}
								€
							</Td>
						</Tr>
					)}

					{/* Submit row for client and admins */}
					<AddNewCount machineId={machineId} />
				</Tbody>
			</Table>
			{isLoading && <Loading />}
		</TableContainer>
	);
};

export default CountsTable;
