import { Flex, GridItem, SimpleGrid } from "@chakra-ui/react";
import UserLoggedGridLayout from "../../layouts/UserLoggedGridLayout";
import ClientsStat from "../../features/adminDashboard/ClientsStat";
import CountsOverviewList from "../../features/adminDashboard/CountsOverviewList";
import {
	useGetMissingCountsQuery,
	useGetCountsToExportQuery,
} from "../../api/copyCountsApi";
import { useGetMonthlyExportedCountsQuery } from "../../api/integrationApi";
import CountsExportedList from "../../features/adminDashboard/CountsExportedList";
import ContractsStat from "../../features/adminDashboard/ContractsStat";
import EndingContractsStat from "../../features/adminDashboard/EndingContractsStat";
import DateSelector from "../../components/DateSelector";
import { useState } from "react";
import { formatISO } from "date-fns";

const today = new Date();

const DashboardPage = () => {
	const [selectedMonth, setSelectedMonth] = useState<number | undefined>(
		undefined
	);
	const [selectedYear, setSelectedYear] = useState<number | undefined>(
		undefined
	);

	const filterDate = new Date(
		selectedYear ?? today.getFullYear(),
		selectedMonth ?? today.getMonth()
	);
	const filterDateStr = formatISO(filterDate, { representation: "date" });

	const { data: missingCounts = [], isFetching: missingCountsIsFetching } =
		useGetMissingCountsQuery(filterDateStr);
	const {
		data: countsReadyToExport = [],
		isFetching: countsReadyToExportIsFetching,
	} = useGetCountsToExportQuery(filterDateStr);
	const { data: exportedCounts, isFetching: exportedCountsIsFetching } =
		useGetMonthlyExportedCountsQuery(filterDateStr);

	return (
		<UserLoggedGridLayout>
			<GridItem colSpan={{ base: 4, md: 10 }}>
				<Flex gap={4}>
					<ClientsStat />
					<ContractsStat />
					<EndingContractsStat />
				</Flex>
			</GridItem>

			<GridItem colSpan={{ base: 4, md: 10 }}>
				<Flex mb={2} gap={4}>
					<DateSelector
						onSelect={(year: string) =>
							setSelectedYear(parseInt(year))
						}
						type={"year"}
						defaultValue={today.getUTCFullYear().toString()}
					/>
					<DateSelector
						onSelect={(month: string) =>
							setSelectedMonth(parseInt(month))
						}
						type={"month"}
						defaultValue={today.getUTCMonth().toString()}
					/>
				</Flex>
				<SimpleGrid
					spacing={4}
					templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
				>
					<CountsOverviewList
						data={missingCounts}
						title="Contagens em falta"
						color="#ffc154"
						isFetching={missingCountsIsFetching}
						date={filterDate}
					/>
					<CountsOverviewList
						data={countsReadyToExport}
						title="Contagens para exportar"
						color="#36c2f3"
						isFetching={countsReadyToExportIsFetching}
						date={filterDate}
					/>
					<CountsExportedList
						data={exportedCounts?.exports ?? []}
						title="Contagens exportadas"
						color="#03cc00"
						stats={
							exportedCounts?.statusCount ?? {
								Billed: 0,
								Rejected: 0,
								Pending: 0,
								Empty: 0,
							}
						}
						isFetching={exportedCountsIsFetching}
					/>
				</SimpleGrid>
			</GridItem>
		</UserLoggedGridLayout>
	);
};

export default DashboardPage;
