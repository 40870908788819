import Hero from "../components/Hero";
import ServicesList from "../components/ServicesList";
import Partners from "../components/Partners";
import LandingPageLayout from "../layouts/LandingPageLayout";
import { useRef } from "react";

const LandingPage = () => {
	const scrollTargetRef = useRef<HTMLDivElement>(null);

	const handleScroll = () => {
		if (scrollTargetRef.current) {
			const offset = 120; // Adjust this value as needed
			const targetPosition =
				scrollTargetRef.current.getBoundingClientRect().top +
				window.scrollY;
			window.scrollTo({
				top: targetPosition - offset,
				behavior: "smooth",
			});
		}
	};

	return (
		<LandingPageLayout>
			<Hero onScrollClick={handleScroll} />

			<div ref={scrollTargetRef}>
				<ServicesList />
			</div>
			
			<Partners />
		</LandingPageLayout>
	);
};

export default LandingPage;

// return (
//   <LandingPageGridLayout>
//     <Container maxW={"5xl"}>
//       <Stack
//         textAlign={"center"}
//         align={"center"}
//         spacing={{ base: 8, md: 10 }}
//         py={{ base: 20, md: 28 }}
//       >
//         {/* <Heading
//           fontWeight={600}
//           fontSize={{ base: "3xl", sm: "4xl", md: "6xl" }}
//           lineHeight={"110%"}
//         >
//           Assisteprint
//         </Heading> */}
// <Flex w={"full"} justifyContent={"center"}>
//   {/* <Illustration height={{ sm: '24rem', lg: '28rem' }} mt={{ base: 12, sm: 16 }} /> */}
//   <Image
//     src="/assets/images/introduction.png"
//     alt="Introduction Image"
//   />
// </Flex>
//         <Text color={"gray.500"} maxW={"3xl"}>
//           Novo website ainda em construcao!
//           <br />
//           Novidades no futuro
//         </Text>
//         {/* <Stack spacing={6} direction={"row"}>
//           <Button
//             rounded={"full"}
//             px={6}
//             colorScheme={"orange"}
//             bg={"orange.400"}
//             _hover={{ bg: "orange.500" }}
//           >
//             Get started
//           </Button>
//           <Button rounded={"full"} px={6}>
//             Learn more
//           </Button>
//         </Stack> */}
//       </Stack>
//     </Container>
//   </LandingPageGridLayout>
// );
