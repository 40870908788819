import {
	useRadio,
	Box,
	useRadioGroup,
	UseRadioProps,
	Button,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverTrigger,
	Portal,
	VStack,
} from "@chakra-ui/react";

type RadioCardProps = UseRadioProps & { children: React.ReactNode };

const RadioCard = (props: RadioCardProps) => {
	const { getInputProps, getRadioProps } = useRadio(props);

	const input = getInputProps();
	const checkbox = getRadioProps();

	return (
		<Box as="label" display={"flex"} width={"100%"}>
			<input {...input} />
			<Box
				{...checkbox}
				cursor="pointer"
				borderWidth="1px"
				borderRadius="md"
				boxShadow="md"
				_checked={{
					bg: "#113052",
					color: "white",
					borderColor: "#113052",
				}}
				_focus={{
					boxShadow: "outline",
				}}
				px={5}
				py={3}
				width={"100%"}
			>
				{props.children}
			</Box>
		</Box>
	);
};

interface ContractTagsProps {
	onChange: (value: string) => void;
	value: string | undefined;
}

const ContractTags = ({ onChange, value }: ContractTagsProps) => {
	const options = [
		"Todos",
		"Termina em < 3 Meses",
		"Termina Fim do Mês",
		"Inativos",
	];

	const { getRootProps, getRadioProps } = useRadioGroup({
		name: "expirationFilters",
		value: value,
		onChange,
	});

	const group = getRootProps();

	return (
		<Popover>
			<PopoverTrigger>
				<Button variant={"outline"}>Filtro fim de contrato</Button>
			</PopoverTrigger>
			<Portal>
				<PopoverContent>
					<PopoverArrow />
					<PopoverCloseButton />
					<PopoverBody>
						<VStack {...group} px={5}>
							{options.map((value) => {
								const radio = getRadioProps({ value });
								return (
									<RadioCard key={value} {...radio}>
										{value}
									</RadioCard>
								);
							})}
						</VStack>
					</PopoverBody>
				</PopoverContent>
			</Portal>
		</Popover>
	);
};

export default ContractTags;
