import { Flex, GridItem } from "@chakra-ui/react";
import UserLoggedGridLayout from "../../layouts/UserLoggedGridLayout";
import MachinesTable from "../../components/admin/MachinesTable";
import { FiPrinter } from "react-icons/fi";
import CountStatCard from "../../components/CountStatCard";
import { useGetMachinesTotalCountQuery } from "../../api/statsApi";

const MachinePage = () => {
	const { data, isLoading } = useGetMachinesTotalCountQuery();

	return (
		<UserLoggedGridLayout>
			<GridItem colSpan={{ base: 4, md: 10 }}>
				<Flex gap={4}>
					<CountStatCard
						label={"Máquinas"}
						result={{
							data,
							isLoading,
						}}
						Icon={FiPrinter}
					/>
				</Flex>
			</GridItem>
			<GridItem colSpan={{ base: 4, md: 10 }}>
				<MachinesTable />
			</GridItem>
		</UserLoggedGridLayout>
	);
};

export default MachinePage;
