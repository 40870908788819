import {
	Card,
	CardHeader,
	Heading,
	CardBody,
	Stack,
	StackDivider,
	Box,
	Text,
	Container,
	Flex,
	Divider,
	AlertIcon,
	Alert,
} from "@chakra-ui/react";
import { Discount } from "../../api/discountsApi";
import { formatDateMonthAndYear } from "../../utils/dateUtils";
import { getDiscountTypeValue, getErrorMessage } from "../../utils/helpers";
import { useGetMachineActiveContractAndDetailsByMachineIdQuery } from "../../api/machineApi";
import { skipToken } from "@reduxjs/toolkit/query";
import Loading from "../../components/Loading";

interface ContractCardProps {
	machineId: number | undefined;
}

const ContractCard = ({ machineId }: ContractCardProps) => {
	const { data, isLoading, isSuccess, isError, error } =
		useGetMachineActiveContractAndDetailsByMachineIdQuery(
			machineId ?? skipToken,
			{
				refetchOnMountOrArgChange: true,
			}
		);

	return (
		<Container>
			<Card>
				<CardHeader>
					<Heading size="md">Contrato</Heading>
				</CardHeader>

				<CardBody>
					{isLoading && <Loading />}

					{isError && (
						<Alert status="warning">
							<AlertIcon />
							{getErrorMessage(error)}
						</Alert>
					)}

					{isSuccess && (
						<Stack
							direction={"row"}
							spacing={8}
							divider={<StackDivider />}
						>
							<Stack
								divider={<StackDivider flex="1 1 auto" />}
								spacing="4"
								flex="1 1 auto"
							>
								<Box>
									<Flex direction="row" align="flex-start">
										<Heading
											size="sm"
											textTransform="uppercase"
											mr={1}
										>
											Datas
										</Heading>
										<Text fontSize="sm" color="gray.600">
											(início e fim)
										</Text>
									</Flex>

									{data.contract ? (
										<Text pt="2" fontSize="sm">
											{new Date(
												data.contract.startDate
											).toLocaleDateString("pt-PT", {
												day: "numeric",
												month: "numeric",
												year: "numeric",
											})}{" "}
											-{" "}
											{new Date(
												data.contract.endDate
											).toLocaleDateString("pt-PT", {
												day: "numeric",
												month: "numeric",
												year: "numeric",
											})}
										</Text>
									) : (
										<Text pt="2" fontSize="sm">
											-
										</Text>
									)}
								</Box>
								<Box>
									<Heading
										size="sm"
										textTransform="uppercase"
									>
										Copias Iniciais
									</Heading>
									{data ? (
										<>
											<Text pt="2" fontSize="sm">
												Preto:{" "}
												{data.initialCountsBlack}
											</Text>
											<Text pt="2" fontSize="sm">
												Cor: {data.initialCountsColor}
											</Text>
										</>
									) : (
										<Text pt="2" fontSize="sm">
											-
										</Text>
									)}
								</Box>
								<Box>
									<Heading
										size="sm"
										textTransform="uppercase"
									>
										A pagar
									</Heading>
									{data.contract ? (
										<Flex>
											<Box>
												<Text pt="2" fontSize="sm">
													Mensalidade:{" "}
													{data.monthlyFee} €
												</Text>
												<Text pt="2" fontSize="sm">
													Preço Preto:{" "}
													{data.blackCopyUnitPrice} €
												</Text>
												<Text pt="2" fontSize="sm">
													Preço Cor:{" "}
													{data.colorCopyUnitPrice} €
												</Text>
											</Box>
										</Flex>
									) : (
										<Text pt="2" fontSize="sm">
											-
										</Text>
									)}
								</Box>
							</Stack>
							<Stack spacing="4" flex="1 1 auto">
								<Heading size="sm" textTransform="uppercase">
									Descontos
								</Heading>
								{data.discounts &&
									data.discounts.map(
										(discount: Discount) => (
											<Box key={discount.id}>
												<Flex
													direction="row"
													align="flex-start"
												>
													<Heading size="sm" mr={1}>
														{getDiscountTypeValue(
															discount.type
														)}
													</Heading>
													{/* {discount.usageDate && (
							<Text
								fontSize="sm"
								color="gray.600"
							>
								(
								{formatDateMonth(
									new Date(
										discount.usageDate
									)
								)}
								)
							</Text>
						)} */}
													<Text
														fontSize="sm"
														color="gray.600"
													>
														(
														{formatDateMonthAndYear(
															new Date(
																discount.startDate
															)
														)}{" "}
														/{" "}
														{formatDateMonthAndYear(
															new Date(
																discount.endDate
															)
														)}
														)
													</Text>
												</Flex>

												<Text pt="2" fontSize="sm">
													Desconto Preto:{" "}
													{
														discount.blackCopyDiscountAmount
													}{" "}
													(-
													{discount.usedBlackCopies})
												</Text>
												<Text pt="2" fontSize="sm">
													Desconto Cor:{" "}
													{
														discount.colorCopyDiscountAmount
													}{" "}
													(-
													{discount.usedColorCopies})
												</Text>
												<Divider mt={4} />
											</Box>
										)
									)}
							</Stack>
						</Stack>
					)}
				</CardBody>
			</Card>
		</Container>
	);
};

export default ContractCard;
