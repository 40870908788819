import {
	Flex,
	Stack,
	Button,
	Heading,
	useColorModeValue,
	Link,
	Image,
} from "@chakra-ui/react";

const NotFoundPage = () => {
	return (
		<Flex
			minH={"100vh"}
			align={"center"}
			justify={"center"}
			bg={useColorModeValue("gray.50", "gray.800")}
			direction={"column"}
		>
			<Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
				<Stack align={"center"}>
					<Link href="/">
						<Image
							w={{ base: "320px", md: "600px" }}
							src="/assets/images/logo.png"
							alt="Assisteprint Logo"
							paddingBottom={"40px"}
						/>
					</Link>
					<Heading fontSize={"3xl"}>Página não encontrada</Heading>
				</Stack>
			</Stack>
			<Link href="/">
				<Button variant={"ghost"} size={"xs"}>
					Voltar à página inicial
				</Button>
			</Link>
		</Flex>
	);
};

export default NotFoundPage;
