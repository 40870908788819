import {
	Alert,
	AlertIcon,
	GridItem,
	Heading,
	Stack,
	Text,
} from "@chakra-ui/react";
import ContractCard from "../features/counts/ContractCard";
import CountsTable from "../features/counts/CountsTable";
import UserLoggedGridLayout from "../layouts/UserLoggedGridLayout";
import { isAdmin } from "../utils/helpers";
import { useAppSelector } from "../app/hooks";
import CountsSelectors from "../features/counts/CountsSelectors";
import CountsBilling from "../features/counts/CountsBilling";
import CountActions from "../features/counts/CountActions";
import { formatDate } from "../utils/dateUtils";
import Loading from "../components/Loading";
import { useSearchParams } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { useGetMachineActiveContractAndDetailsByMachineIdQuery } from "../api/machineApi";
import { skipToken } from "@reduxjs/toolkit/query";

const CountsPage = () => {
	const { role, clientId } = useAppSelector((state) => state.auth);
	const {
		contractNotFoundError,
		isContractInactive,
		isLoading,
		contractEnded,
		alerts,
	} = useAppSelector((state) => state.counts);

	const [searchParams, setSearchParams] = useSearchParams();

	// Memoize and validate query parameters
	const { machineId, year } = useMemo(() => {
		const clientId = searchParams.get("clientId");
		const machineId = searchParams.get("machineId");
		const year = searchParams.get("year");

		return {
			clientId: clientId ? Number(clientId) : undefined,
			machineId: machineId ? Number(machineId) : undefined,
			year: year ? Number(year) : new Date().getUTCFullYear(),
		};
	}, [searchParams]);

	const { data: machineContractDetails } =
		useGetMachineActiveContractAndDetailsByMachineIdQuery(
			machineId ?? skipToken,
			{
				refetchOnMountOrArgChange: true,
			}
		);

	useEffect(() => {
		if (clientId) {
			const params = new URLSearchParams();
			if (clientId) params.append("clientId", String(clientId));
			if (machineId) params.append("machineId", String(machineId));
			if (year) params.append("year", String(year));
			setSearchParams(params);
		}
	}, [clientId]);

	if (isLoading) <Loading fillViewPort />;

	return (
		<UserLoggedGridLayout>
			<GridItem colSpan={{ base: 4, md: 10 }}>
				<Heading>Inserir Contagens</Heading>
			</GridItem>
			<GridItem colSpan={{ base: 4, md: 2 }}>
				<CountsSelectors />
			</GridItem>
			<GridItem colSpan={{ base: 4, md: 5 }}>
				{isAdmin(role) && <ContractCard machineId={machineId} />}
			</GridItem>
			<GridItem colSpan={{ base: 4, md: 3 }}>
				<CountsBilling
					contractId={machineContractDetails?.contract?.id}
				/>
			</GridItem>
			<GridItem colSpan={{ base: 4, md: 10 }}>
				{/* Alerts  */}
				<Stack spacing={3} mt={5}>
					{!machineId && (
						<Alert status="info">
							<AlertIcon />
							Selecione uma máquina
						</Alert>
					)}

					{machineId && contractNotFoundError && (
						<Alert status="warning">
							<AlertIcon />
							Contrato não existe
						</Alert>
					)}

					{machineId && contractEnded && (
						<Alert status="info">
							<AlertIcon />
							Contrato terminado
						</Alert>
					)}

					{isContractInactive && (
						<Alert status="info">
							<AlertIcon />
							<Text>
								Contrato cessado em:{" "}
								{machineContractDetails?.contract &&
									formatDate(
										machineContractDetails.contract
											.ceaseDate
									)}
							</Text>
						</Alert>
					)}

					{alerts.map((alert) => (
						<Alert key={alert.title} status={alert.status}>
							<AlertIcon />
							<Text>{alert.title}</Text>
						</Alert>
					))}
				</Stack>

				<CountsTable
					contractId={machineContractDetails?.contract?.id}
					machineId={machineId}
					year={year}
				/>

				<CountActions
					contractId={machineContractDetails?.contract?.id}
					machineId={machineId}
					year={year}
				/>
			</GridItem>
		</UserLoggedGridLayout>
	);
};

export default CountsPage;
