import {
	Container,
	Flex,
	Box,
	Heading,
	VStack,
	Button,
	Text,
	FormControl,
	FormLabel,
	InputGroup,
	InputLeftElement,
	Input,
	Textarea,
	useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { BsPerson } from "react-icons/bs";
import {
	MdPhone,
	MdEmail,
	MdLocationOn,
	MdOutlineEmail,
} from "react-icons/md";
import LandingPageLayout from "../layouts/LandingPageLayout";

const ContactUsPage = () => {
	const toast = useToast();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);

		formData.append("access_key", "850e3b4d-f718-47fb-8c8a-314c95147bc4");

		setIsLoading(true);
		// Show the loading toast
		const loadingToast = toast({
			status: "loading",
			title: "A enviar a mensagem...",
			description: "Aguarde enquanto enviamos a mensagem.",
		});

		try {
			const response = await fetch("https://api.web3forms.com/submit", {
				method: "POST",
				body: formData,
			});

			const data = await response.json();

			toast.close(loadingToast);

			if (data.success) {
				const form = event.target as HTMLFormElement;
				form.reset();
				toast({
					status: "success",
					title: `Mensagem enviada com sucesso`,
					description: `Entraremos em contacto`,
					isClosable: true,
				});
			} else {
				toast({
					status: "error",
					title: `Erro ao enviar mensagem`,
					description: `Algo correu mal`,
					isClosable: true,
				});
			}
		} catch (error) {
			// Close the loading toast
			toast.close(loadingToast);

			toast({
				status: "error",
				title: `Erro ao enviar mensagem`,
				description: `Algo correu mal`,
				isClosable: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<LandingPageLayout>
			<Container
				className="contactUsPage"
				minH={"calc(100vh - 75px)"}
				maxW="full"
				centerContent
				overflow="hidden"
			>
				<Flex>
					<Box
						bg="#113052"
						color="white"
						borderRadius="lg"
						m={{ sm: 4, md: 16, lg: 20 }}
						mt={{ base: 10, lg: 20 }}
						p={{ base: 4, lg: 16 }}
					>
						<Box p={4}>
							<Flex
								gap={{ base: 3, sm: 3, md: 5, lg: 20 }}
								flexWrap={"wrap"}
								justifyContent={"center"}
							>
								<Flex>
									<Box>
										<Heading>Contactos</Heading>
										<Text
											mt={{ sm: 3, md: 3, lg: 5 }}
											color="white"
										>
											Preencha o formulário abaixo para
											entrar em contacto
										</Text>
										<Box
											py={{
												base: 5,
												sm: 5,
												md: 8,
												lg: 10,
											}}
										>
											<VStack
												pl={0}
												spacing={3}
												alignItems="flex-start"
											>
												<Button
													size="md"
													height="48px"
													variant="ghost"
													color="white"
													_hover={{
														border: "2px solid #E2091B",
													}}
													leftIcon={
														<MdPhone
															color="#E2091B"
															size="20px"
														/>
													}
													justifyContent={
														"flex-start"
													}
												>
													+351 232 422 057{"  "}
													<Text
														as="span"
														fontSize={"xs"}
														color="white"
														fontWeight={"400"}
													>
														(chamada de rede fixa
														nacional)
													</Text>
												</Button>
												<Button
													size="md"
													height="48px"
													width="250px"
													variant="ghost"
													color="white"
													_hover={{
														border: "2px solid #E2091B",
													}}
													leftIcon={
														<MdEmail
															color="#E2091B"
															size="20px"
														/>
													}
													justifyContent={
														"flex-start"
													}
												>
													geral@assisteprint.com
												</Button>
												<Button
													size="md"
													height="48px"
													variant="ghost"
													color="white"
													_hover={{
														border: "2px solid #E2091B",
													}}
													leftIcon={
														<MdLocationOn
															color="#E2091B"
															size="20px"
														/>
													}
													justifyContent={
														"flex-start"
													}
												>
													R. João Mendes 124,
													3500-141 Viseu
												</Button>
											</VStack>
										</Box>
										{/* <HStack
											mt={{ lg: 10, md: 10 }}
											spacing={5}
											px={5}
											alignItems="flex-start"
										>
											<IconButton
												aria-label="facebook"
												variant="ghost"
												size="lg"
												isRound={true}
												_hover={{ bg: "#E2091B" }}
												icon={
													<MdFacebook
														size="28px"
														color="white"
													/>
												}
											/>
										</HStack> */}
									</Box>
								</Flex>
								<Flex>
									<Box bg="white" borderRadius="lg">
										<Box m={8} color="#0B0E3F">
											<VStack spacing={5}>
												<form onSubmit={onSubmit}>
													<FormControl isRequired>
														<FormLabel>
															Nome
														</FormLabel>
														<InputGroup borderColor="#E0E1E7">
															<InputLeftElement pointerEvents="none">
																<BsPerson color="gray.800" />
															</InputLeftElement>
															<Input
																type="text"
																size="md"
																name="name"
															/>
														</InputGroup>
													</FormControl>
													<FormControl isRequired>
														<FormLabel>
															Email
														</FormLabel>
														<InputGroup borderColor="#E0E1E7">
															<InputLeftElement pointerEvents="none">
																<MdOutlineEmail color="gray.800" />
															</InputLeftElement>
															<Input
																type="email"
																size="md"
																name="email"
															/>
														</InputGroup>
													</FormControl>
													<FormControl isRequired>
														<FormLabel>
															Mensagem
														</FormLabel>
														<Textarea
															borderColor="gray.300"
															_hover={{
																borderRadius:
																	"gray.300",
															}}
															name="message"
														/>
													</FormControl>
													<FormControl
														id="name"
														float="right"
													>
														<Button
															mt={4}
															color="white"
															type="submit"
															isLoading={
																isLoading
															}
														>
															Enviar
														</Button>
													</FormControl>
												</form>
											</VStack>
										</Box>
									</Box>
								</Flex>
							</Flex>
						</Box>
					</Box>
				</Flex>
			</Container>
		</LandingPageLayout>
	);
};

export default ContactUsPage;
