import { apiSlice } from "./apiSlice";
import { Job } from "./jobsApi";
import { ValidationResponse } from "./machineApi";

enum UserRole {
	Admin = "Admin",
	User = "User",
}

enum UserStatus {
	Unregistered = "Unregistered",
	Registered = "Registered",
	Closed = "Closed",
}

export interface User {
	id: number;
	name: string;
	email: string;
	password: string;
	role: UserRole;
	status: UserStatus;
	createdAt: Date;
	updatedAt: Date;
	jobs: Job[];
	comments: Comment[];
}

export const userApi = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		getAllUsers: build.query<User[], void>({
			query: () => "/users",
			// providesTags: (result) => providesList(result, "User"),
		}),
		isEmailValid: build.mutation<
			ValidationResponse,
			{ userId: number; email: string }
		>({
			query: ({ userId, email }) => ({
				url: `/users/${userId}/isEmailValid`,
				method: "POST",
				body: {
					email,
				},
			}),
		}),
	}),
});

// export react hook
export const { useGetAllUsersQuery, useIsEmailValidMutation } = userApi;
