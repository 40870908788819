import {
	Flex,
	Box,
	FormControl,
	FormLabel,
	Input,
	Stack,
	Button,
	Heading,
	useColorModeValue,
	useToast,
	Link,
	Image,
	Alert,
	AlertIcon,
	Text,
	AlertTitle,
} from "@chakra-ui/react";
import { useState, FormEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getErrorMessage } from "../utils/helpers";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { FaCheckCircle } from "react-icons/fa";
import {
	useResetPasswordMutation,
	useSendResetEmailMutation,
} from "../api/authApi";

const PasswordResetPage = () => {
	const navigate = useNavigate();
	const toast = useToast();

	const { token } = useParams();

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");

	const [
		sendResetEmail,
		{ isSuccess: isResetEmailSent, isError: isResetEmailError },
	] = useSendResetEmailMutation();

	const [
		resetPassword,
		{ isSuccess: isResetSuccess, isError: isResetError },
	] = useResetPasswordMutation();

	const handleSubmitResetRequest = async (
		event: FormEvent<HTMLFormElement>
	) => {
		event.preventDefault();

		const promise = sendResetEmail({ email }).unwrap();
		toast.promise(promise, {
			loading: {
				title: "A enviar email de recuperação",
				description: "Aguarde enquanto processamos o seu pedido.",
			},
			success: {
				title: "Email enviado",
				description: "Verifique a sua caixa de correio",
			},
			error: (err: Error) => {
				const errorMessage = getErrorMessage(
					err as FetchBaseQueryError | SerializedError
				);

				setError(errorMessage);
				return {
					title: "Erro ao enviar o email",
					description: errorMessage,
				};
			},
		});
	};

	const handleSubmitReset = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const promise = resetPassword({
			token: token ?? "",
			password,
		}).unwrap();
		toast.promise(promise, {
			loading: {
				title: "A repor a password",
				description: "Aguarde enquanto processamos o seu pedido.",
			},
			success: {
				title: "Password reposta com sucesso",
			},
			error: (err: Error) => {
				const errorMessage = getErrorMessage(
					err as FetchBaseQueryError | SerializedError
				);

				setError(errorMessage);
				return {
					title: "Erro ao repor a password",
					description: errorMessage,
				};
			},
		});
	};

	return (
		<Flex
			minH={"100vh"}
			align={"center"}
			justify={"center"}
			bg={useColorModeValue("gray.50", "gray.800")}
			direction={"column"}
		>
			<Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
				<Stack align={"center"}>
					<Link href="/">
						<Image
							w={{ base: "320px", md: "600px" }}
							src="/assets/images/logo.png"
							alt="Assisteprint Logo"
							paddingBottom={"40px"}
						/>
					</Link>
					<Heading fontSize={"3xl"}>Repor a palavra-passe</Heading>
					{isResetEmailError && error && (
						<Alert status="error" mt={"20px"}>
							<AlertIcon />
							<AlertTitle>{error}</AlertTitle>
						</Alert>
					)}
				</Stack>
				<Box
					rounded={"lg"}
					bg={useColorModeValue("white", "gray.700")}
					boxShadow={"lg"}
					p={8}
				>
					{isResetEmailSent ? (
						<Flex
							alignItems={"center"}
							direction={"column"}
							gap={10}
						>
							<FaCheckCircle size={90} color="green" />
							<Heading
								as={"h4"}
								size={"md"}
								textAlign={"center"}
							>
								Verifique o email que foi enviado para a sua
								conta.
							</Heading>
							<Text
								as={"span"}
								size={"xs"}
								textAlign={"center"}
								mt={-8}
								color="gray.600"
							>
								Vai ser possivel fazer a alteração da password
								por apenas 30 minutos
							</Text>
						</Flex>
					) : isResetSuccess ? (
						<Flex
							alignItems={"center"}
							direction={"column"}
							gap={10}
						>
							<FaCheckCircle size={90} color="green" />
							<Heading
								as={"h4"}
								size={"md"}
								textAlign={"center"}
							>
								Password reposta com sucesso
							</Heading>
							<Button onClick={() => navigate("/login")}>
								Login
							</Button>
						</Flex>
					) : token ? (
						<form onSubmit={handleSubmitReset}>
							<Stack spacing={4}>
								<FormControl id="password" isRequired>
									<FormLabel>Nova Password</FormLabel>
									<Input
										id="new-password"
										isInvalid={isResetError}
										type="password"
										onChange={(e) =>
											setPassword(e.target.value)
										}
										autoComplete="new-password"
										aria-required="true"
									/>
								</FormControl>
								<Stack spacing={5}>
									<Button type="submit">
										Repor Password
									</Button>
								</Stack>
							</Stack>
						</form>
					) : (
						<form onSubmit={handleSubmitResetRequest}>
							<Stack spacing={4}>
								<FormControl id="email" isRequired>
									<FormLabel>Email</FormLabel>
									<Input
										isInvalid={isResetEmailError}
										type="email"
										onChange={(e) =>
											setEmail(e.target.value)
										}
										autoComplete="email"
										aria-required="true"
									/>
								</FormControl>
								<Stack spacing={5}>
									<Button type="submit">Repor</Button>
								</Stack>
							</Stack>
						</form>
					)}
				</Box>
			</Stack>
			<Button
				variant={"ghost"}
				onClick={() => navigate("/")}
				size={"xs"}
			>
				Voltar à página inicial
			</Button>
		</Flex>
	);
};

export default PasswordResetPage;
