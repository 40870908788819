import { MinusIcon, AddIcon } from "@chakra-ui/icons";
import {
	SimpleGrid,
	Card,
	CardBody,
	Flex,
	FormControl,
	FormLabel,
	Input,
	FormErrorMessage,
	CardFooter,
	IconButton,
	useToast,
} from "@chakra-ui/react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { CustomerFormType } from "./CustomerValidationSchemas";
import { ChangeEvent } from "react";
import { useIsEmailValidMutation } from "../../api/userApi";
import { useDispatch } from "react-redux";
import { setStepValidity } from "../customerFormSlice";
import Loading from "../../components/Loading";

const CustomerStep3 = () => {
	const dispatch = useDispatch();
	const toast = useToast();
	const {
		control,
		formState: { errors },
		register,
		getValues,
		setError,
		trigger,
	} = useFormContext<CustomerFormType>();

	const { fields, append, remove } = useFieldArray({
		control: control,
		name: `users`,
		keyName: "_id",
	});

	const [isEmailValid, { isLoading }] = useIsEmailValidMutation();

	const handleIsEmailValid = async (
		e: ChangeEvent<HTMLInputElement>,
		index: number
	) => {
		const userId = getValues(`users.${index}.id`);

		const fieldName = `users.${index}.email` as const;

		const isValid = await trigger(fieldName);
		if (!isValid) return;

		const email = e.target.value;
		if (!email) return;
		try {
			const response = await isEmailValid({
				email,
				userId,
			}).unwrap();

			if (!response.isValid) {
				toast({
					status: "warning",
					title: `Email inválido`,
					description: response.message,
					isClosable: true,
				});

				setError(fieldName, {
					type: "400",
					message: "Email já existe",
				});

				dispatch(setStepValidity(false));
				return;
			}
			dispatch(setStepValidity(true));
		} catch (error) {
			toast({
				status: "error",
				title: `Erro na validação`,
				description: `Algo correu mal`,
				isClosable: true,
			});
		}
	};

	if (isLoading) return <Loading fillViewPort />;

	return (
		<SimpleGrid spacing={4} templateColumns="repeat(3, 1fr)">
			{fields.map((field, index) => (
				<Card key={field._id}>
					<CardBody>
						<Flex flexDirection={"column"} gap={3}>
							<FormControl
								isInvalid={
									!!errors?.users?.[index]?.name?.message
								}
								isRequired
							>
								<FormLabel>Nome</FormLabel>
								<Input
									type="text"
									{...register(
										`users.${index}.name` as const
									)}
								/>
								<FormErrorMessage>
									{errors.users?.[index]?.name?.message}
								</FormErrorMessage>
							</FormControl>

							<FormControl
								isInvalid={
									!!errors?.users?.[index]?.email?.message
								}
								isRequired
							>
								<FormLabel>Email</FormLabel>
								<Input
									type="text"
									{...register(
										`users.${index}.email` as const
									)}
									onBlur={(e) =>
										handleIsEmailValid(e, index)
									}
								/>
								<FormErrorMessage>
									{errors.users?.[index]?.email?.message}
								</FormErrorMessage>
							</FormControl>
						</Flex>
					</CardBody>
					<CardFooter>
						{fields.length > 0 && (
							<IconButton
								aria-label="Remove object"
								icon={<MinusIcon />}
								variant="outline"
								onClick={() => remove(index)}
							/>
						)}
					</CardFooter>
				</Card>
			))}
			<Flex alignItems={"center"}>
				<IconButton
					aria-label="Add object"
					icon={<AddIcon />}
					onClick={() =>
						append({
							id: 0,
							name: "",
							email: "",
						})
					}
				/>
			</Flex>
		</SimpleGrid>
	);
};

export default CustomerStep3;
