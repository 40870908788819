import {
	useCeaseContractMutation,
	useGetContractByIdQuery,
	useGetContractDetailsByContractIdQuery,
} from "../../api/contractApi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Badge,
	Box,
	Button,
	Card,
	CardBody,
	CardHeader,
	Flex,
	GridItem,
	Heading,
	ListItem,
	Stack,
	StackDivider,
	UnorderedList,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import UserLoggedGridLayout from "../../layouts/UserLoggedGridLayout";
import { FetchBaseQueryError, skipToken } from "@reduxjs/toolkit/query";
import Loading from "../../components/Loading";
import { Text } from "@chakra-ui/react";
import { CheckCircleIcon, SmallCloseIcon } from "@chakra-ui/icons";
import { DiscountWithMachines } from "../../api/discountsApi";
import {
	getBillingName,
	getDiscountTypeValue,
	getErrorMessage,
} from "../../utils/helpers";
import { SerializedError } from "@reduxjs/toolkit";
import { useRef } from "react";

const ContractDetailsPage = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const toast = useToast();
	const { id } = useParams();

	const { isOpen, onOpen, onClose } = useDisclosure();
	const cancelRef = useRef<HTMLButtonElement>(null);

	const {
		data: contract,
		isLoading: contractIsLoading,
		isFetching: contractIsFecthing,
	} = useGetContractByIdQuery(
		id
			? {
					id: Number(id),
					includeClients: true,
			  }
			: skipToken
	);

	const { data, isFetching, isLoading } =
		useGetContractDetailsByContractIdQuery(
			id
				? {
						id: Number(id),
						includeMachines: true,
						includeDiscounts: true,
				  }
				: skipToken
		);

	const [ceaseContract] = useCeaseContractMutation();

	if (isLoading || isFetching || contractIsLoading || contractIsFecthing) {
		return (
			<UserLoggedGridLayout>
				<GridItem colSpan={{ base: 4, md: 10 }}>
					<Loading fillViewPort />
				</GridItem>
			</UserLoggedGridLayout>
		);
	}

	if (!data || !contract) {
		return <div>No data available.</div>;
	}

	const discountMap = new Map();

	// Iterate through each contract and its discounts
	data.forEach((contract) => {
		(contract.discounts || []).forEach((discount) => {
			if (!discountMap.has(discount.id)) {
				// Initialize the discount entry if it doesn't exist
				discountMap.set(discount.id, {
					...discount,
					machines: [],
				});
			}

			// Add the current machine to the discount entry
			const discountEntry = discountMap.get(discount.id);
			discountEntry.machines.push({
				...contract.machine,
				contractDetails: {
					contractType: contract.contractType,
					initialCountsBlack: contract.initialCountsBlack,
					initialCountsColor: contract.initialCountsColor,
					blackCopyUnitPrice: contract.blackCopyUnitPrice,
					colorCopyUnitPrice: contract.colorCopyUnitPrice,
					monthlyFee: contract.monthlyFee,
					active: contract.active,
				},
			});
		});
	});

	// Convert the Map to an array of discounts with machines
	const discountsWithMachines: DiscountWithMachines[] = Array.from(
		discountMap.values()
	);

	const handleBackClick = () => {
		if (location.key === "default") {
			// This means the user navigated directly to this page
			navigate("/admin/contracts"); // Replace with the route you want to redirect to
		} else {
			// Go back to the previous page
			navigate(-1);
		}
	};

	const handleCeaseContract = (id: number) => {
		let promise;
		try {
			promise = ceaseContract(id).unwrap();

			toast.promise(promise, {
				loading: {
					title: "A cessar o contrato",
					description: "Aguarde enquanto processamos o seu pedido.",
				},
				success: {
					title: "Contrato cessado com sucesso",
				},
				error: (err: Error) => {
					const errorMessage = getErrorMessage(
						err as FetchBaseQueryError | SerializedError
					);
					return {
						title: "Erro ao cessar o contrato",
						description: errorMessage,
					};
				},
			});
		} catch (error) {
			console.debug("Cease error:", error);
		}
	};

	return (
		<UserLoggedGridLayout>
			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Apagar cliente
						</AlertDialogHeader>

						<AlertDialogBody>
							Tem a certeza que pretende cessar o contrato?
							{/* <strong>Operação não pode ser revertida!</strong> */}
							{/* <Text fontSize={"xs"}>
								(Serão apagados contratos, contagens, descontos
								etc.)
							</Text> */}
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={onClose}>
								Cancelar
							</Button>
							<Button
								colorScheme="red"
								onClick={() => {
									handleCeaseContract(contract.id);
									onClose();
								}}
								ml={3}
								variant={"outline"}
							>
								Cessar
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			<GridItem colSpan={{ base: 4, md: 10 }}>
				<Flex justify={"space-between"} alignItems={"center"}>
					<Flex alignItems={"center"} gap={4}>
						<Heading my={6}>Detalhes de contrato</Heading>
						<Button onClick={handleBackClick} variant={"ghost"}>
							Voltar
						</Button>
					</Flex>
					<Flex gap={4}>
						{contract.active && (
							<Button
								variant={"outline"}
								colorScheme="red"
								onClick={onOpen}
							>
								Cessar contrato
							</Button>
						)}
						{/* <Button
							variant={"solid"}
							onClick={() => handleClientDelete(client.id)}
						>
							Apagar
						</Button> */}
					</Flex>
				</Flex>
				<Flex justifyContent={"space-evenly"} px={10}>
					<Text as="strong">{contract.client?.name || ""}</Text>
					<Text>
						<Text as="span">Data de inicio:</Text>{" "}
						<Text as="strong">
							{new Date(contract.startDate).toLocaleDateString(
								"pt-PT",
								{
									day: "numeric",
									month: "numeric",
									year: "numeric",
								}
							)}
						</Text>
					</Text>
					<Text>
						<Text as="span">Data de término:</Text>{" "}
						<Text as="strong">
							{new Date(contract.endDate).toLocaleDateString(
								"pt-PT",
								{
									day: "numeric",
									month: "numeric",
									year: "numeric",
								}
							)}
						</Text>
					</Text>
					<Text>
						<Text as="span">Tipo de faturação:</Text>{" "}
						<Text as="strong">
							{getBillingName(contract.billingType)}
						</Text>
					</Text>
					<Text>
						<Text as="span">Ativo:</Text>{" "}
						{contract.active ? (
							<CheckCircleIcon color="green.500" />
						) : (
							<SmallCloseIcon color="red.500" />
						)}
					</Text>
				</Flex>
			</GridItem>
			<GridItem
				colStart={{ base: 1, md: 2 }}
				colEnd={{ base: 4, md: 10 }}
			>
				<Flex gap={10} mt={10} flexWrap={"wrap"}>
					{data.map((contractDetails) => {
						const {
							id,
							machine,
							initialCountsBlack,
							initialCountsColor,
							contractType,
							monthlyFee,
							colorCopyUnitPrice,
							blackCopyUnitPrice,
						} = contractDetails;

						if (!machine) return null;

						return (
							<Card key={id}>
								<CardHeader>
									<Flex
										alignItems={"center"}
										justifyContent={"space-between"}
										gap={5}
									>
										<Box>
											<Heading size="md">
												{machine.sageId} -{" "}
												{machine.brand.name}{" "}
												{machine.model.name}
											</Heading>
											<Stack direction={"row"}>
												<Text
													fontSize="xs"
													color="gray.500"
												>
													{machine.serialNumber}
												</Text>
												<Text
													fontSize="xs"
													color="gray.500"
												>
													{machine.type.name}
												</Text>
											</Stack>
										</Box>
										{machine.active ? (
											<CheckCircleIcon color="green" />
										) : (
											<SmallCloseIcon color="red" />
										)}
									</Flex>
								</CardHeader>
								<CardBody>
									<Stack
										divider={<StackDivider />}
										spacing="4"
										flex={1}
									>
										<Stack>
											<Text>
												<Text as="span">
													Tipo de contrato:
												</Text>{" "}
												<Text as="strong">
													{contractType}
												</Text>
											</Text>
											<Text>
												<Text as="span">
													Copias iniciais preto:
												</Text>{" "}
												<Text as="strong">
													{initialCountsBlack}
												</Text>
											</Text>
											<Text>
												<Text as="span">
													Copias iniciais cor:
												</Text>{" "}
												<Text as="strong">
													{initialCountsColor}
												</Text>
											</Text>
										</Stack>
										<Stack>
											<Text>
												<Text as="span">
													Mensalidade:
												</Text>{" "}
												<Text as="strong">
													{monthlyFee} €
												</Text>
											</Text>
											<Text>
												<Text as="span">
													Preço Preto:
												</Text>{" "}
												<Text as="strong">
													{blackCopyUnitPrice} €
												</Text>
											</Text>
											<Text>
												<Text as="span">
													Preço Cor:
												</Text>{" "}
												<Text as="strong">
													{colorCopyUnitPrice} €
												</Text>
											</Text>
										</Stack>
									</Stack>
								</CardBody>
							</Card>
						);
					})}
				</Flex>
				{/* <Divider my={6} /> */}
				<Accordion allowToggle mt={10}>
					{discountsWithMachines.map((discount, index) => {
						const {
							type,
							machines,
							blackCopyDiscountAmount,
							usedBlackCopies,
							colorCopyDiscountAmount,
							usedColorCopies,
						} = discount;

						return (
							<AccordionItem key={index}>
								<AccordionButton>
									<Box as="span" flex="1">
										<Flex alignItems={"center"} gap={5}>
											<Text fontSize={"xl"}>
												Desconto {index + 1}
											</Text>
											<Badge>
												{getDiscountTypeValue(type)}
											</Badge>
										</Flex>
									</Box>

									<AccordionIcon />
								</AccordionButton>
								<AccordionPanel pb={4}>
									{/* TODO: revisit this to add date  */}
									<Box mb={3}>
										<Text pt="2" fontSize="sm">
											Desconto Preto:{" "}
											{blackCopyDiscountAmount} (-
											{usedBlackCopies})
										</Text>
										<Text pt="2" fontSize="sm">
											Desconto Cor:{" "}
											{colorCopyDiscountAmount} (-
											{usedColorCopies})
										</Text>
									</Box>
									<UnorderedList>
										{(machines || []).map(
											(machine, index) => {
												const {
													sageId,
													brand,
													model,
													serialNumber,
												} = machine;
												return (
													<ListItem key={index}>
														{sageId} - {brand.name}{" "}
														{model.name}{" "}
														<Text
															as={"span"}
															fontSize="xs"
															color="gray.500"
														>
															({serialNumber})
														</Text>
													</ListItem>
												);
											}
										)}
									</UnorderedList>
								</AccordionPanel>
							</AccordionItem>
						);
					})}
				</Accordion>
			</GridItem>
		</UserLoggedGridLayout>
	);
};

export default ContractDetailsPage;
