import { useGetActiveClientsCountStatsQuery } from "../../api/statsApi";
import { FiUsers } from "react-icons/fi";
import CountStat from "../../components/CountStatCard";

const ClientsStat = () => {
	const { data, isLoading } = useGetActiveClientsCountStatsQuery();

	return (
		<CountStat
			label={"Clientes"}
			result={{
				data,
				isLoading,
			}}
			Icon={FiUsers}
		/>
	);
};

export default ClientsStat;
