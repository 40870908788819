import { apiSlice } from "./apiSlice";

// interface ClientCountStat {
// totalClients: number;
// previousMonthStart: number;
// netChange: number;
// }

export interface CountStat {
	count: number;
}

export const statsApi = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		getActiveClientsCountStats: build.query<CountStat, void>({
			query: () => "/stats/clients/count",
		}),
		getActiveContractsCountStats: build.query<CountStat, void>({
			query: () => "/stats/contracts/count",
		}),
		getEndingContractsCountStats: build.query<CountStat, void>({
			query: () => "/stats/contracts/endingCount",
		}),
		getMachinesTotalCount: build.query<CountStat, void>({
			query: () => "/stats/machines/count",
			providesTags: (result) =>
				result ? [{ type: "Machine", id: "LIST" }] : [],
		}),
	}),
});

// export react hook
export const {
	useGetActiveClientsCountStatsQuery,
	useGetActiveContractsCountStatsQuery,
	useGetEndingContractsCountStatsQuery,
	useGetMachinesTotalCountQuery,
} = statsApi;
