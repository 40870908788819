import {
	Tr,
	Td,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
} from "@chakra-ui/react";
import { formatDateMonth } from "../../utils/dateUtils";
import { isAdmin } from "../../utils/helpers";
import { setBlackCount, setColorCount } from "./countsSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useGetMachineActiveContractAndDetailsByMachineIdQuery } from "../../api/machineApi";
import { skipToken } from "@reduxjs/toolkit/query";

interface AddNewCountProps {
	machineId: number | undefined;
}

const AddNewCount = ({ machineId }: AddNewCountProps) => {
	const dispatch = useAppDispatch();

	const { role } = useAppSelector((state) => state.auth);
	const { newCountData, isSubmitAvailable } = useAppSelector(
		(state) => state.counts
	);

	const { data: machineContractDetails } =
		useGetMachineActiveContractAndDetailsByMachineIdQuery(
			machineId ?? skipToken,
			{
				refetchOnMountOrArgChange: true,
			}
		);

	return (
		<>
			{machineContractDetails && isSubmitAvailable && (
				<Tr>
					<Td>
						{formatDateMonth(new Date(newCountData.submitDate))}
					</Td>
					<Td>
						<NumberInput
							defaultValue={0}
							min={0}
							max={10000000}
							w={"120px"}
							onChange={(_, valueAsNumber) => {
								dispatch(setBlackCount(valueAsNumber));
							}}
						>
							<NumberInputField />
							<NumberInputStepper>
								<NumberIncrementStepper />
								<NumberDecrementStepper />
							</NumberInputStepper>
						</NumberInput>
					</Td>
					{isAdmin(role) && (
						<Td>{machineContractDetails.blackCopyUnitPrice} €</Td>
					)}
					{isAdmin(role) && <Td></Td>}
					<Td></Td>
					<Td>
						<NumberInput
							defaultValue={0}
							min={0}
							max={10000000}
							w={"120px"}
							onChange={(_, valueAsNumber) => {
								dispatch(setColorCount(valueAsNumber));
							}}
						>
							<NumberInputField />
							<NumberInputStepper>
								<NumberIncrementStepper />
								<NumberDecrementStepper />
							</NumberInputStepper>
						</NumberInput>
					</Td>
					{isAdmin(role) && (
						<Td>{machineContractDetails.colorCopyUnitPrice} €</Td>
					)}
					{isAdmin(role) && <Td></Td>}
					<Td></Td>
					<Td>{/* {newCountData.total.toFixed(2)} */}- €</Td>
				</Tr>
			)}
		</>
	);
};

export default AddNewCount;
