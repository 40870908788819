import {
	Box,
	Button,
	Flex,
	Grid,
	GridItem,
	Heading,
	Image,
	Link,
	Text,
} from "@chakra-ui/react";
import "../global.css";

interface HeroProps {
	onScrollClick: () => void;
}

const Hero = ({ onScrollClick }: HeroProps) => {
	return (
		<Box className="wave-container">
			<Grid
				templateColumns={{
					base: "repeat(4, 1fr)",
					lg: "repeat(12, 1fr)",
				}}
				gap={"20px"}
				maxW={"1504px"}
				mx={{ base: "16px", lg: "auto" }}
				mt={{ base: "30px", lg: "0" }}
				// minH={"calc(100vh-76px"}
				minH={"90vh"}
			>
				<GridItem
					colStart={{ base: 1, lg: 2 }}
					colSpan={{ base: 4, lg: 5 }}
					pb={{ base: "0", lg: "100px" }}
				>
					<Flex
						direction={"column"}
						h={"100%"}
						justifyContent={"center"}
					>
						<Heading size={"3xl"}>
							Soluções de impressão
							<br />
							<Text as={"span"} color="#E2091B">
								à sua medida
							</Text>
						</Heading>
						<Text pt={4} color={"gray"}>
							Impressoras e multifuncionais, assistência técnica
							especializada e equipamentos de escritório para
							garantir produtividade e eficiência no seu dia a
							dia.
						</Text>
						<Flex pt={8} gap={4} flexWrap={"wrap"}>
							<Button
								variant={"secondary"}
								size={"lg"}
								onClick={onScrollClick}
							>
								Os nossos serviços
							</Button>
							<Link href="/contact">
								<Button
									variant={"outline"}
									colorScheme={"red"}
									size={"lg"}
								>
									{/* Promoções */}
									Entrar em contacto
								</Button>
							</Link>
						</Flex>
					</Flex>
				</GridItem>
				<GridItem
					colStart={{ base: 1, lg: 7 }}
					colSpan={{ base: 4, lg: 5 }}
					pb={"100px"}
				>
					<Flex h={"100%"} justifyContent={"center"}>
						<Image
							src="/assets/images/introduction.png"
							alt="Introduction Image"
							objectFit={"contain"}
						/>
					</Flex>
				</GridItem>
			</Grid>
		</Box>
	);
};

export default Hero;
