import {
	Box,
	Card,
	CardBody,
	Flex,
	Heading,
	Image,
	SimpleGrid,
	Stack,
	Text,
} from "@chakra-ui/react";
import "../global.css";

const ServicesList = () => {
	return (
		<Box bg={"#113052"} minH={"calc(100vh - 100px)"} pb={"80px"}>
			<Flex justify={"center"} pt={10} pb={20}>
				<Heading color={"white"} size={"2xl"}>
					Os nossos serviços
				</Heading>
			</Flex>
			<SimpleGrid
				columns={{ base: 1, md: 2, lg: 4 }}
				gap={"20px"}
				maxW={"1504px"}
				mx={{ base: "16px", lg: "auto" }}
				px={10}
				autoRows={"min-content"}
				justifyItems={"center"}
			>
				<Card boxShadow="feature-list-card" rounded={20}>
					<CardBody>
						<Flex justifyContent={"center"}>
							<Image
								h={"200px"}
								src="/assets/images/featureList-printers.png"
								alt="Imagem do equipamento"
							/>
						</Flex>
						<Stack mt="6" spacing="3" textAlign={"left"}>
							<Heading size="md">
								Venda e Aluguer de Equipamentos de Impressão
							</Heading>
							<Text color={"gray"}>
								Multifuncionais e outros dispositivos de alta
								performance com opções flexíveis para compra ou
								aluguer.
							</Text>
						</Stack>
					</CardBody>
				</Card>
				<Card boxShadow="feature-list-card" rounded={20}>
					<CardBody>
						<Flex justifyContent={"center"}>
							<Image
								h={"200px"}
								src="/assets/images/featureList-fixing.jpg"
								alt="Reparação de uma impressora"
								borderRadius="lg"
							/>
						</Flex>
						<Stack mt="6" spacing="3">
							<Heading size="md">
								Assistência Técnica Especializada
							</Heading>
							<Text color={"gray"}>
								Contamos com técnicos qualificados para
								manutenção e suporte em todos os nossos
								equipamentos.
							</Text>
						</Stack>
					</CardBody>
				</Card>
				<Card boxShadow="feature-list-card" rounded={20}>
					<CardBody>
						<Flex justifyContent={"center"}>
							<Image
								h={"200px"}
								src="/assets/images/featureList-equippment.jpg"
								alt="Equipamento de escritório"
							/>
						</Flex>
						<Stack mt="6" spacing="3">
							<Heading size="md">
								Equipamentos de Escritório Modernos
							</Heading>
							<Text color={"gray"}>
								Fornecemos tudo o que o seu escritório precisa:
								desde mesas e cadeiras a acessórios
								tecnológicos.
							</Text>
						</Stack>
					</CardBody>
				</Card>
				<Card boxShadow="feature-list-card" rounded={20}>
					<CardBody>
						<Flex justifyContent={"center"}>
							<Image
								h={"200px"}
								src="/assets/images/featureList-software.jpg"
								alt="Software"
							/>
						</Flex>
						<Stack mt="6" spacing="3">
							<Heading size="md">Soluções de Software</Heading>
							<Text color={"gray"}>
								Oferecemos softwares certificados e homologados
								pelos fabricantes dos equipamentos
								{/* , projetados
								para atender às necessidades do seu negócio com
								eficiência e simplicidade. */}
							</Text>
						</Stack>
					</CardBody>
				</Card>
			</SimpleGrid>
		</Box>
	);
};

export default ServicesList;
