import {
	Flex,
	Box,
	FormControl,
	FormLabel,
	Input,
	Stack,
	Button,
	Heading,
	useColorModeValue,
	Alert,
	AlertIcon,
	AlertTitle,
	useToast,
	Image,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getErrorMessage, isAdmin, isClient } from "../utils/helpers";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { decodeToken } from "../utils/tokenUtils";
import { useLogoutMutation, useRegisterMutation } from "../api/authApi";
import { useAppSelector } from "../app/hooks";

const RegisterPage = () => {
	const navigate = useNavigate();
	const toast = useToast();

	const { token } = useParams();

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const { isAuthenticated } = useAppSelector((state) => state.auth);

	const [register, { isError }] = useRegisterMutation();
	const [logout] = useLogoutMutation();

	useEffect(() => {
		if (token) {
			const decoded_data = decodeToken(token);
			setEmail(decoded_data?.email || "");
			setName(decoded_data?.name || "");
		}
	}, [token]);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (!token) return;

		const promise = register({ name, email, password, token }).unwrap();
		toast.promise(promise, {
			loading: {
				title: "A registar o utilizador",
				description: "Aguarde enquanto processamos o seu pedido.",
			},
			success: {
				title: "Registo efetuado",
			},
			error: (err: Error) => {
				const errorMessage = getErrorMessage(
					err as FetchBaseQueryError | SerializedError
				);

				return {
					title: "Ocorreu um erro durante o registo",
					description: errorMessage,
				};
			},
		});
		promise
			.then((data) => {
				const decoded_data = decodeToken(
					data.accessToken ?? data.tmpToken ?? ""
				);

				if (!decoded_data) return;

				const role = decoded_data.role;
				if (isAdmin(role)) {
					navigate("/admin");
				} else if (isClient(role)) {
					if ("tmpToken" in data && "companies" in data) {
						navigate("/select-company");
					} else navigate("/client");
				}
			})
			.catch(() => {});
	};

	const handleLogout = () => {
		const promise = logout().unwrap();

		toast.promise(promise, {
			loading: {
				title: "A encerrar sessão",
				description: "Aguarde enquanto processamos o seu pedido.",
			},
			success: { title: "Sessão encerrada com sucesso" },
			error: (err: Error) => {
				const errorMessage = getErrorMessage(
					err as FetchBaseQueryError | SerializedError
				);

				return {
					title: "Erro ao encerrar sessão",
					description: errorMessage,
				};
			},
		});
	};

	return (
		<Flex
			minH={"100vh"}
			align={"center"}
			justify={"center"}
			bg={useColorModeValue("gray.50", "gray.800")}
		>
			<Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
				<Stack align={"center"}>
					<Image
						w={{ base: "320px", md: "600px" }}
						src="/assets/images/logo.png"
						alt="Assisteprint Logo"
						paddingBottom={"40px"}
					/>
					<Heading fontSize={"3xl"}>Registe a sua conta</Heading>
					{isError && (
						<Alert status="error" mt={"20px"}>
							<AlertIcon />
							<AlertTitle>
								Ocorreu um erro durante o registo
							</AlertTitle>
						</Alert>
					)}
					{isAuthenticated && (
						<Alert status="warning" mt={"20px"}>
							<AlertIcon />
							<AlertTitle>
								Ja tem sessão iniciada{" "}
								<Button
									onClick={handleLogout}
									size={"xs"}
									variant={"ghost"}
								>
									Terminar Sessão
								</Button>
							</AlertTitle>
						</Alert>
					)}
				</Stack>
				<Box
					rounded={"lg"}
					bg={useColorModeValue("white", "gray.700")}
					boxShadow={"lg"}
					p={8}
				>
					<form onSubmit={handleSubmit}>
						<Stack spacing={4}>
							<FormControl id="name" isRequired>
								<FormLabel>Name</FormLabel>
								<Input
									isDisabled={isAuthenticated}
									type="text"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</FormControl>
							<FormControl id="email" isRequired>
								<FormLabel>Email</FormLabel>
								<Input
									isDisabled={isAuthenticated}
									type="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</FormControl>
							<FormControl id="password" isRequired>
								<FormLabel>Password</FormLabel>
								<Input
									isDisabled={isAuthenticated}
									type="password"
									value={password}
									onChange={(e) =>
										setPassword(e.target.value)
									}
								/>
							</FormControl>
							<Stack spacing={10} mt={5}>
								<Button
									type="submit"
									isDisabled={isAuthenticated}
								>
									Registar
								</Button>
							</Stack>
						</Stack>
					</form>
				</Box>
			</Stack>
		</Flex>
	);
};

export default RegisterPage;
