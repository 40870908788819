import { extendTheme } from "@chakra-ui/react";
import { StylesConfig } from "react-select";

const customTheme = extendTheme({
	components: {
		Button: {
			baseStyle: {
				fontWeight: "bold", // Change the base font weight
			},
			variants: {
				solid: {
					bg: "#E2091B",
					color: "white",
					_hover: {
						bg: "red.400",
					},
				},
				secondary: {
					bg: "#113052",
					color: "white",
					_hover: {
						bg: "darkblue.300",
					},
				},
			},
		},
	},
	shadows: {
		"feature-list-card":
			"0 4px 6px rgba(0, 0, 0, 0.4), 0 8px 15px rgba(0, 0, 0, 0.3)",
	},
});

export default customTheme;

// #113052
// #E2091B

export interface OptionType {
	value: number | string;
	label: string;
}

export const selectCustomStyles: StylesConfig<OptionType, false> = {
	control: (provided, state) => ({
		...provided,
		borderColor: state.isFocused ? "#113052" : "#e2e8f0",
		boxShadow: state.isFocused ? "0 0 0 1px #113052" : "none",
		"&:hover": {
			borderColor: "#87aed8",
		},
	}),
	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isSelected ? "#113052" : "white",
		color: state.isSelected ? "white" : "black",
		"&:hover": {
			backgroundColor: state.isSelected ? "#113052" : "#87aed8",
		},
	}),
	placeholder: (provided) => ({
		...provided,
		color: "#718096",
	}),
	singleValue: (provided) => ({
		...provided,
		color: "#2d3748",
	}),
};
