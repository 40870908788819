import { apiSlice } from "./apiSlice";
import { Client } from "./clientApi";
import { Machine } from "./machineApi";

export interface Office {
	id: number;
	name: string;
	email: string;
	address: string;
	postalCode: string;
	location: string;
	telephone: string;
	active: boolean;
	latitude?: string;
	longitude?: string;
	mainOffice: boolean;
	machines?: Machine[];
	createdAt: Date;
	updatedAt: Date;
	clientId: number;
	client?: Client;
}

export const officeApi = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		getOfficesAndMachinesByClientId: build.query<Office[], number>({
			query: (id) => `/offices/client/${id}?includeMachines=true`,
			providesTags: (result, _error, id) =>
				result ? [{ type: "Client", id }] : [],
		}),
	}),
});

// export react hook
export const { useGetOfficesAndMachinesByClientIdQuery } = officeApi;
