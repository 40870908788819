import {
	TableContainer,
	Table,
	Thead,
	Tr,
	Th,
	Tbody,
	Td,
	IconButton,
	Flex,
} from "@chakra-ui/react";
import { Client } from "../../api/clientApi";
import { CheckCircleIcon, SearchIcon, SmallCloseIcon } from "@chakra-ui/icons";
import { FiEdit2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

interface ClientsTableProps {
	clients: Client[];
}

const ClientsTable = ({ clients }: ClientsTableProps) => {
	const navigate = useNavigate();

	return (
		<TableContainer>
			<Table variant="striped" colorScheme="gray">
				<Thead>
					<Tr>
						<Th
							maxWidth="200px"
							whiteSpace="normal"
							wordBreak="break-word"
						>
							Nome
						</Th>
						<Th>Email</Th>
						<Th>Telefone</Th>
						<Th>NIF</Th>
						<Th>Ativo</Th>
						<Th></Th>
					</Tr>
				</Thead>
				<Tbody>
					{/* {(rowsPerPage > 0
							? filteredClients.slice(
									page * rowsPerPage,
									page * rowsPerPage + rowsPerPage
							  )
							: filteredClients */}
					{clients.map((client: Client) => (
						<Tr key={client.id}>
							<Td
								maxWidth={{base: "", md: "200px"}}
								whiteSpace={{base: "", md: "normal"}}
								wordBreak={{base: "unset", md: "break-word"}}
							>
								{client.name}
							</Td>
							<Td>{client.email}</Td>
							<Td>{client.telephone}</Td>
							<Td>{client.nif}</Td>
							<Td>
								{client.active ? (
									<CheckCircleIcon color="green" />
								) : (
									<SmallCloseIcon color="red" />
								)}
							</Td>
							<Td>
								<Flex gap={3}>
									<IconButton
										aria-label="Ver detalhes do cliente"
										onClick={() =>
											navigate(
												`/admin/clients/${client.id}/details`
											)
										}
									>
										<SearchIcon color={"white"} />
									</IconButton>
									<IconButton
										aria-label="Editar Cliente"
										variant={"outline"}
										colorScheme="blue"
										onClick={() =>
											navigate(
												`/admin/edit/client/${client.id}`
											)
										}
									>
										<FiEdit2 />
									</IconButton>
								</Flex>
							</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
		</TableContainer>
	);
};

export default ClientsTable;
