import React from "react";
import { Select } from "@chakra-ui/react";
import { Office } from "../api/officeApi";

interface MachineSelectorProps {
	offices: Office[];
	value?: string;
	onMachineSelect: (machineId: number) => void;
}

const MachineSelector: React.FC<MachineSelectorProps> = ({
	offices,
	value,
	onMachineSelect,
}) => {
	return (
		<Select
			placeholder="Selecione a maquina"
			mt={8}
			onChange={(e) => onMachineSelect(parseInt(e.target.value, 10))}
			value={value}
		>
			{offices.map((office) => (
				<optgroup key={office.id} label={office.name}>
					{office.machines?.map((machine) => (
						<option key={machine.id} value={machine.id}>
							{machine.sageId} - {machine.brand.name} {machine.model.name}
						</option>
					))}
				</optgroup>
			))}
		</Select>
	);
};

export default MachineSelector;
