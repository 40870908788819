import { ComponentType } from "react";

export type ErrorResponse = {
	message: string;
};

export enum Role {
	Admin = "Admin",
	User = "User",
	// Client = "Client",
	Guest = "Guest",
}

export type LoginResponse = {
	token: string;
	id: number;
	email: string;
	role: Role;
};

export type LoginRequest = {
	email: string;
	password: string;
};

export type ApiRefreshRequest = {
	refreshToken: string;
};

export type ApiRefreshResponse = {
	accessToken: string;
};

export type TokenInfo = {
	id: number;
	email: string;
	name: string;
	role: Role;
	clientId: number | null;
	iat: number;
};

export interface CustomError {
	message: string;
	status: number;
}

// Define form field configuration interface
export interface FormFieldConfig {
	label: string;
	name: string;
	type: string;
	isRequired: boolean;
	component?: ComponentType;
	defaultValue?: unknown;
	options?: { label: string; value: string }[];
	placeholder?: string;
}

export interface GetExtraArgs {
	includeUsers?: boolean;
	includeOffices?: boolean;
	includeMachines?: boolean;
	// includeMachinesState?: boolean;
	includeClients?: boolean;
	includeMachinesContractDetails?: boolean;
	includeDiscounts?: boolean;
}

export type GetExtraArgsWithId = GetExtraArgs & {
	id: number;
};
