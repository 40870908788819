import {
	Card,
	CardBody,
	CardHeader,
	Flex,
	Stat,
	StatLabel,
	StatNumber,
} from "@chakra-ui/react";
import { CountStat } from "../api/statsApi";
import Loading from "./Loading";

interface CountStatProps {
	label: string;
	Icon: React.ElementType;
	result: {
		data: CountStat | undefined;
		isLoading: boolean;
	};
}

const CountStatCard = ({ label, Icon, result }: CountStatProps) => {
	const { data, isLoading } = result;

	return (
		<Card direction={"row"} alignItems={"center"}>
			<CardHeader>
				<Flex alignItems={"center"}>
					<Icon size={"50px"} />
				</Flex>
			</CardHeader>
			<CardBody>
				<Stat>
					<StatLabel>{label}</StatLabel>
					{isLoading ? (
						<Loading />
					) : (
						<StatNumber>{data?.count ?? "-"}</StatNumber>
					)}
				</Stat>
			</CardBody>
		</Card>
	);
};

export default CountStatCard;
