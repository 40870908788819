import {
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Badge,
	Box,
	Button,
	Flex,
	IconButton,
	List,
	ListItem,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import { formatMachineName, getErrorMessage } from "../../utils/helpers";
import TextWithLabel from "../../components/TextWithLabel";
import {
	ExportCount,
	ExportStatus,
	useUpdateCountExportStatusMutation,
} from "../../api/integrationApi";
import { Client } from "../../api/clientApi";
import { HamburgerIcon } from "@chakra-ui/icons";
import { MdDone, MdOutlineCancel, MdPendingActions } from "react-icons/md";
import { getColorForStatus } from "./CountsExportedList";
import { useNavigate } from "react-router-dom";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import InvoiceConversionAlertDialog from "./InvoiceConversionAlertDialog";
import { useRef, useState } from "react";

interface CountsExportedListItemProps {
	client: Client;
	exportInfo: ExportCount[];
}

const CountsExportedListItem = ({
	client,
	exportInfo,
}: CountsExportedListItemProps) => {
	const toast = useToast();
	const navigate = useNavigate();

	const [selectedDoc, setSelectedDoc] = useState<{
		docNumber?: string;
		docSeries?: string;
		docType?: string;
	}>({
		docNumber: undefined,
		docSeries: undefined,
		docType: undefined,
	});

	const { isOpen, onOpen, onClose } = useDisclosure();
	const cancelRef = useRef<HTMLButtonElement>(null);

	const [updateExportStatus] = useUpdateCountExportStatusMutation();

	const handleStatusChange = (
		status: ExportStatus,
		docNumber?: string,
		docSeries?: string,
		docType?: string,
		copyCountExportIds?: number[]
	) => {
		if (docNumber === "null") docNumber = undefined;
		if (status === ExportStatus.Billed) {
			setSelectedDoc({
				docNumber,
				docSeries,
				docType,
			});
			onOpen();
			return;
		}

		const promise = updateExportStatus({
			docNumber,
			docSeries,
			docType,
			status,
			copyCountExportIds,
		}).unwrap();
		toast.promise(promise, {
			loading: {
				title: "A atualizar estado",
				description: "Aguarde enquanto processamos o seu pedido.",
			},
			success: { title: "Estado atualizado com sucesso" },
			error: (err: Error) => {
				const errorMessage = getErrorMessage(
					err as FetchBaseQueryError | SerializedError
				);

				return {
					title: "Erro ao atualizar estado",
					description: errorMessage,
				};
			},
		});
	};

	return (
		<>
			<InvoiceConversionAlertDialog
				selectedDoc={selectedDoc}
				isOpen={isOpen}
				onClose={onClose}
				cancelRef={cancelRef}
			/>
			<AccordionItem>
				{({ isExpanded }) => (
					<>
						<h2>
							<AccordionButton
								_expanded={{
									bg: "#ECECEC",
								}}
							>
								<Box flex="1" textAlign="left">
									{client.name}
								</Box>
								<AccordionIcon />
							</AccordionButton>
						</h2>
						<AccordionPanel
							pb={4}
							bg={isExpanded ? "#ECECEC" : ""}
						>
							<Box p={3} bg={"white"} borderRadius={5}>
								<Flex
									pb={5}
									justifyContent={"space-between"}
									flexWrap={"wrap"}
									gap={5}
								>
									<TextWithLabel
										text={client.email}
										label="Email"
									/>
									<TextWithLabel
										text={client.telephone}
										label="Telefone"
									/>
								</Flex>
								<List>
									{Object.entries(
										groupBy(exportInfo, "number", "status")
									).map(
										([
											documentNumber,
											{ exports, status },
										]) => {
											const exportDoc = exportInfo.find(
												(e) =>
													e.number ===
													Number(documentNumber)
											);

											const exportIds = exports.map(
												(e) => e.id
											);

											return (
												<Box
													key={documentNumber}
													mb={4}
												>
													<Flex
														direction={"row"}
														alignItems={"center"}
														gap={4}
														pb={1}
													>
														<Text
															fontSize="sm"
															color="gray.600"
														>
															{`Documento nº${
																documentNumber &&
																documentNumber !==
																	"null"
																	? documentNumber
																	: "-"
															}`}
															{exportDoc?.invoice
																? ` -> Fatura nº${exportDoc.invoice.number}`
																: ""}
														</Text>
														<Menu>
															<MenuButton
																as={IconButton}
																size={"xs"}
																aria-label="Options"
																icon={
																	<HamburgerIcon
																		boxSize={
																			4
																		}
																	/>
																}
																variant="outline"
															/>
															<MenuList>
																{!exportDoc &&
																	status !==
																		ExportStatus.Empty && (
																		<MenuItem
																			icon={
																				<MdPendingActions
																					fontSize={
																						"20px"
																					}
																				/>
																			}
																			onClick={() =>
																				handleStatusChange(
																					ExportStatus.Empty,
																					documentNumber,
																					undefined,
																					undefined,
																					exportIds
																				)
																			}
																		>
																			Vazio
																		</MenuItem>
																	)}
																{status !==
																	ExportStatus.Pending &&
																	documentNumber !==
																		"null" && (
																		<MenuItem
																			icon={
																				<MdPendingActions
																					fontSize={
																						"20px"
																					}
																				/>
																			}
																			onClick={() =>
																				handleStatusChange(
																					ExportStatus.Pending,
																					documentNumber,
																					exportDoc?.series,
																					exportDoc?.documentType,
																					exportIds
																				)
																			}
																		>
																			Pendente
																		</MenuItem>
																	)}
																{status !==
																	ExportStatus.Billed &&
																	documentNumber !==
																		"null" && (
																		<MenuItem
																			icon={
																				<MdDone
																					fontSize={
																						"20px"
																					}
																				/>
																			}
																			onClick={() =>
																				handleStatusChange(
																					ExportStatus.Billed,
																					documentNumber,
																					exportDoc?.series,
																					exportDoc?.documentType,
																					exportIds
																				)
																			}
																		>
																			Faturado
																		</MenuItem>
																	)}
																{status !==
																	ExportStatus.Rejected && (
																	<MenuItem
																		icon={
																			<MdOutlineCancel
																				fontSize={
																					"20px"
																				}
																			/>
																		}
																		onClick={() =>
																			handleStatusChange(
																				ExportStatus.Rejected,
																				documentNumber,
																				exportDoc?.series,
																				exportDoc?.documentType,
																				exportIds
																			)
																		}
																	>
																		Anulado
																	</MenuItem>
																)}
															</MenuList>
														</Menu>
													</Flex>
													<List>
														{exports.map(
															(
																copyCountExport
															) => (
																<ListItem
																	key={
																		copyCountExport.id
																	}
																	display={
																		"flex"
																	}
																>
																	<Badge
																		colorScheme={getColorForStatus(
																			copyCountExport.status
																		)}
																	>
																		{formatMachineName(
																			copyCountExport.machine
																		) ??
																			""}
																	</Badge>
																</ListItem>
															)
														)}
													</List>
												</Box>
											);
										}
									)}
								</List>
								<Button
									size={"xs"}
									mt={3}
									onClick={() =>
										navigate(
											`/admin/clients/${client.id}/details`
										)
									}
								>
									Detalhes
								</Button>
							</Box>
						</AccordionPanel>
					</>
				)}
			</AccordionItem>
		</>
	);
};

export default CountsExportedListItem;

function groupBy<T, K extends keyof T>(
	array: T[],
	key: K,
	statusKey: K
): Record<string, { exports: T[]; status: string }> {
	return array.reduce((acc, item) => {
		const keyValue = String(item[key]); // Ensure the key is treated as a string
		const statusValue = String(item[statusKey]); // Ensure the status key is treated as a string
		if (!acc[keyValue]) {
			acc[keyValue] = { exports: [], status: statusValue };
		}
		acc[keyValue].exports.push(item);
		return acc;
	}, {} as Record<string, { exports: T[]; status: string }>);
}
