import { useGetEndingContractsCountStatsQuery } from "../../api/statsApi";
import { FiFileMinus } from "react-icons/fi";
import CountStat from "../../components/CountStatCard";

const EndingContractsStat = () => {
	const { data, isLoading } = useGetEndingContractsCountStatsQuery();

	return (
		<CountStat
			label={"Contratos a terminar"}
			result={{
				data,
				isLoading,
			}}
			Icon={FiFileMinus}
		/>
	);
};

export default EndingContractsStat;
