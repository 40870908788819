import React from "react";
import { Select } from "@chakra-ui/react";
import { monthNames } from "../utils/dateUtils";

type DateType = "year" | "month";

const PLACEHOLDER: Record<DateType, string> = {
	year: "Selecione o ano",
	month: "Selecione o mês",
};

interface DateSelectorProps {
	onSelect: (date: string) => void;
	selectRef?: React.RefObject<HTMLSelectElement>;
	value?: string;
	placeholder?: string;
	type: DateType;
	defaultValue?: string;
}

const DateSelector: React.FC<DateSelectorProps> = ({
	onSelect,
	selectRef,
	value,
	placeholder,
	type,
	defaultValue,
}) => {
	const currentDate = new Date();
	let options: { label: string; value: string }[] = [];

	if (type === "month") {
		// Create options for months with date strings as values
		options = monthNames.map((month, index) => {
			return {
				label: month,
				value: index.toString(),
			};
		});
	} else if (type === "year") {
		const currentYear = currentDate.getUTCFullYear();
		options = Array.from(
			{ length: currentYear - 2000 + 1 },
			(_, index) => {
				const year = currentYear - index;
				return {
					label: year.toString(),
					value: year.toString(),
				};
			}
		);
	}

	return (
		<Select
			placeholder={placeholder ?? PLACEHOLDER[type]}
			onChange={(e) => onSelect(e.target.value)}
			ref={selectRef}
			value={value}
			defaultValue={defaultValue}
		>
			{options.map(({ label, value }) => (
				<option key={value} value={value}>
					{label}
				</option>
			))}
		</Select>
	);
};

export default DateSelector;
