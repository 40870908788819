import { Flex } from "@chakra-ui/react";
import { OptionType, selectCustomStyles } from "../../app/theme";
import MachineSelector from "../../components/MachineSelector";
import YearSelector from "../../components/YearSelector";
import { isAdmin } from "../../utils/helpers";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useGetAllClientsQuery } from "../../api/clientApi";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetOfficesAndMachinesByClientIdQuery } from "../../api/officeApi";
import { useMemo, useRef } from "react";
import Select, { SingleValue } from "react-select";
import { useSearchParams } from "react-router-dom";
import { resetAlerts } from "./countsSlice";

const CountsSelectors = () => {
	const dispatch = useAppDispatch();

	const [searchParams, setSearchParams] = useSearchParams();

	// Memoize and validate query parameters
	const { clientId, machineId, year } = useMemo(() => {
		const clientId = searchParams.get("clientId");
		const machineId = searchParams.get("machineId");
		const year = searchParams.get("year");

		return {
			clientId: clientId ? Number(clientId) : null,
			machineId: machineId ? Number(machineId) : null,
			year: year ? Number(year) : null,
		};
	}, [searchParams]);

	const { role } = useAppSelector((state) => state.auth);

	const { data: clientsData } = useGetAllClientsQuery(undefined, {
		skip: !isAdmin(role),
	});

	const { data: officesData } = useGetOfficesAndMachinesByClientIdQuery(
		clientId ?? skipToken,
		{
			refetchOnMountOrArgChange: true,
		}
	);

	const handleClientSelect = (clientOption: SingleValue<OptionType>) => {
		if (clientOption) {
			const params = new URLSearchParams({
				clientId: String(clientOption.value),
			});
			setSearchParams(params);
			dispatch(resetAlerts());
		}
	};

	const selectRef = useRef<HTMLSelectElement>(null);
	const handleMachineSelect = async (machineId: number) => {
		const currentYear = new Date().getFullYear();
		const params = new URLSearchParams({
			clientId: String(clientId),
			machineId: String(machineId),
			year: String(currentYear),
		});
		setSearchParams(params);
		dispatch(resetAlerts());
	};

	const handleYearSelect = (year: number) => {
		if (year && machineId) {
			const params = new URLSearchParams({
				clientId: String(clientId),
				machineId: String(machineId),
				year: String(year),
			});
			setSearchParams(params);
		}
		dispatch(resetAlerts());
	};

	return (
		<Flex direction={"column"}>
			{isAdmin(role) && clientsData && (
				<Select
					options={clientsData.map((client) => {
						return {
							label: client.name,
							value: client.id,
						};
					})}
					onChange={handleClientSelect}
					styles={selectCustomStyles}
					placeholder="Selecione um cliente"
					defaultValue={
						clientId && clientsData.some((c) => c.id === clientId)
							? {
									label:
										clientsData.find(
											(c) => c.id === clientId
										)?.name || "",
									value:
										clientsData.find(
											(c) => c.id === clientId
										)?.id || 0,
							  }
							: undefined
					}
				/>
			)}
			<MachineSelector
				offices={officesData ?? []}
				onMachineSelect={handleMachineSelect}
				value={machineId?.toString()}
			/>
			{isAdmin(role) && (
				<YearSelector
					onYearSelect={handleYearSelect}
					selectRef={selectRef}
					value={year?.toString()}
				/>
			)}
		</Flex>
	);
};

export default CountsSelectors;
