import { Route, Routes } from "react-router-dom";
import styled from "@emotion/styled";
import { ChakraProvider } from "@chakra-ui/react";
import { useEffect } from "react";
import LandingPage from "./pages/Landing.page";
import LoginPage from "./pages/Login.page";
import BillingsPage from "./pages/admin/Billings.page";
import ClientsPage from "./pages/admin/Clients.page";
import DashboardPage from "./pages/admin/Dashboard.page";
import CustomerHomePage from "./pages/CustomerHome.page";
import PageCountsPage from "./pages/Counts.page";
import customTheme from "./app/theme";
import RequireAuth from "./utils/RequireAuth";
import { Role } from "./utils/constants";
import ContractPage from "./pages/admin/Contract.page";
import ContractFormPage from "./pages/admin/ContractForm.page";
import ContractDetailsPage from "./pages/admin/ContractDetails.Page";
import RegisterPage from "./pages/Register.page";
import { isAdmin } from "./utils/helpers";
import { setAdmin } from "./features/counts/countsSlice";
import CustomerDetailsPage from "./pages/admin/CustomerDetails.page";
import CustomerFormPage from "./pages/admin/CustomerForm.page";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import ContactUsPage from "./pages/ContactUs.page";
import PasswordResetPage from "./pages/PasswordReset.page";
import MachinePage from "./pages/admin/Machine.page";
import SelectCompany from "./pages/SelectCompany";
import NotFoundPage from "./pages/NotFoundPage";
import { pt } from "date-fns/locale";
import { setDefaultOptions } from "date-fns";

export const LayoutWithNavbar = styled.div`
	margin-top: 135px;
`;

setDefaultOptions({ locale: pt });

function App() {
	const dispatch = useAppDispatch();
	const { isAuthenticated, role, clientId } = useAppSelector(
		(state) => state.auth
	);

	useEffect(() => {
		dispatch(setAdmin(isAdmin(role)));
	}, [clientId, isAuthenticated, role, dispatch]);

	return (
		<ChakraProvider
			theme={customTheme}
			toastOptions={{ defaultOptions: { position: "top" } }}
		>
			<Routes>
				{/* public routes */}
				<Route path="/" element={<LandingPage />} />
				<Route path="/login" element={<LoginPage />} />
				<Route path="/select-company" element={<SelectCompany />} />
				<Route path="/register/:token" element={<RegisterPage />} />
				<Route path="/contact" element={<ContactUsPage />} />
				<Route
					path="/password-reset"
					element={<PasswordResetPage />}
				/>
				<Route
					path="/password-reset/:token"
					element={<PasswordResetPage />}
				/>
				{/* protected routes */}
				<Route
					path="/client"
					element={<RequireAuth allowedRoles={[Role.User]} />}
				>
					<Route path="" element={<CustomerHomePage />} />
				</Route>
				<Route
					path="/admin"
					element={<RequireAuth allowedRoles={[Role.Admin]} />}
				>
					<Route path="" element={<DashboardPage />} />
					{/* <Route path="/jobs" element={<JobsPage />} /> */}

					{/* Customers */}
					<Route path="clients" element={<ClientsPage />} />
					<Route
						path="create/client"
						element={<CustomerFormPage />}
					/>
					<Route
						path="edit/client/:id"
						element={<CustomerFormPage />}
					/>
					<Route
						path="clients/:id/details"
						element={<CustomerDetailsPage />}
					/>
					{/* ---- */}

					<Route path="billing" element={<BillingsPage />} />

					{/* Contracts */}
					<Route path="contracts" element={<ContractPage />} />
					<Route
						path="contracts/:id/details"
						element={<ContractDetailsPage />}
					/>
					<Route
						path="create/contracts"
						element={<ContractFormPage />}
					/>
					<Route
						path="edit/contract/:id"
						element={<ContractFormPage />}
					/>
					{/* ---- */}

					{/* Machines */}
					<Route path="machines" element={<MachinePage />} />
					{/* ---- */}
				</Route>
				{/* commonn routes */}
				<Route
					path="/client"
					element={
						<RequireAuth allowedRoles={[Role.User, Role.Admin]} />
					}
				>
					<Route path="counts" element={<PageCountsPage />} />
				</Route>
				{/* This route will render if no other route matches */}
				<Route path="*" element={<NotFoundPage />} />
			</Routes>
		</ChakraProvider>
	);
}

export default App;
