import {
	Box,
	Container,
	SimpleGrid,
	Stack,
	useColorModeValue,
	Text,
	Image,
	VStack,
} from "@chakra-ui/react";

// const ListHeader = ({ children }: { children: ReactNode }) => {
// 	return (
// 		<Text fontWeight={"500"} fontSize={"lg"} mb={2}>
// 			{children}
// 		</Text>
// 	);
// };

const Footer = () => {
	return (
		<Box
			bg={useColorModeValue("gray.50", "gray.900")}
			color={useColorModeValue("gray.700", "gray.200")}
		>
			<Container as={Stack} maxW={"6xl"} py={10}>
				<SimpleGrid
					templateColumns={{
						base: "1fr",
						md: "2fr 1fr 1fr 1fr 1fr",
					}}
					spacing={8}
					justifyItems={{ base: "center", lg: "left" }}
				>
					<Stack spacing={4}>
						<Box>
							<Image
								w="300px"
								src="/assets/images/logo.png"
								alt="Assisteprint Logo"
							/>
						</Box>
						<VStack alignItems={"flex-start"} spacing={2}>
							<Text fontSize={"sm"}>
								R. João Mendes 124, 3500-141 Viseu
							</Text>
							<Text fontSize={"sm"}>
								+351 232 422 057
							</Text>
							<Text fontSize={"sm"}>geral@assisteprint.com</Text>
						</VStack>
						<Text fontSize={"sm"}>
							© 2024 Assisteprint. Todos os direitos reservados{" "}
							<br />
						</Text>
						<Text fontSize={"8px"} lineHeight={"1px"}>
							<a href="http://www.freepik.com">
								Illustrations Designed by vectorjuice / Freepik
							</a>
						</Text>
					</Stack>
					{/* <Stack align={"flex-start"}>
            <ListHeader>Company</ListHeader>
            <Box as="a" href={"#"}>
              About
            </Box>
            <Box as="a" href={"#"}>
              Contact
            </Box>
            <Box as="a" href={"#"}>
              Partners
            </Box>
          </Stack> */}
					{/* <Stack align={"flex-start"}>
            <ListHeader>Support</ListHeader>
            <Box as="a" href={"#"}>
              Terms of Service
            </Box>
            <Box as="a" href={"#"}>
              Legal
            </Box>
            <Box as="a" href={"#"}>
              Privacy Policy
            </Box>
          </Stack> */}
					{/* <Stack align={"flex-start"}>
            <ListHeader>Follow Us</ListHeader>
            <Box as="a" href={"#"}>
              Facebook
            </Box>
            <Box as="a" href={"#"}>
              LinkedIn
            </Box>
          </Stack> */}
				</SimpleGrid>
			</Container>
		</Box>
	);
};

export default Footer;
