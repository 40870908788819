import {
	Accordion,
	Badge,
	Box,
	Card,
	CardBody,
	CardHeader,
	Checkbox,
	Divider,
	Flex,
	Heading,
	Text,
} from "@chakra-ui/react";
import Loading from "../../components/Loading";
import { ExportCount } from "../../api/integrationApi";
import { Client } from "../../api/clientApi";
import { useState } from "react";
import CountsExportedListItem from "./CountsExportedListItem";

interface CountsExportedListProps {
	data: {
		client: Client;
		exportInfo: ExportCount[];
	}[];
	title: string;
	color: string;
	stats?: {
		Billed: number;
		Rejected: number;
		Pending: number;
	};
	isFetching: boolean;
}

const CountsExportedList = ({
	data,
	title,
	color,
	stats,
	isFetching,
}: CountsExportedListProps) => {
	// State to store selected filters
	const [selectedStatuses, setSelectedStatuses] = useState<string[]>([
		"Billed",
		"Pending",
		"Rejected",
		"Empty",
	]);

	// Handle checkbox toggle
	const handleStatusChange = (status: string) => {
		setSelectedStatuses(
			(prevSelected) =>
				prevSelected.includes(status)
					? prevSelected.filter((s) => s !== status) // Remove from selection
					: [...prevSelected, status] // Add to selection
		);
	};

	return (
		<Card borderTop={`6px solid ${color}`} h={"490px"}>
			<CardHeader>
				<Flex alignItems={"center"} justifyContent={"space-between"}>
					<Heading size={"lg"}>{title}</Heading>
					<Text fontSize="sm" color="gray.600">
						({isFetching ? "-" : data.length})
					</Text>
				</Flex>
				{stats && (
					<Flex
						mt={3}
						gap={2}
						justifyContent={"space-around"}
						flexWrap={"wrap"}
					>
						{Object.entries(stats).map(([status, count]) => (
							<Flex
								key={status}
								direction="column"
								align="center"
							>
								<Checkbox
									isChecked={selectedStatuses.includes(
										status
									)}
									onChange={() => handleStatusChange(status)}
									size="lg"
								>
									<Badge
										colorScheme={getColorForStatus(status)}
									>
										{count} {status}
									</Badge>
								</Checkbox>
							</Flex>
						))}
					</Flex>
				)}
				<Divider pt={3} />
			</CardHeader>
			<CardBody overflow={"auto"}>
				{isFetching ? (
					<Loading />
				) : (
					<Box>
						<Accordion allowToggle>
							{data.map(
								({ client, exportInfo }, index) =>
									exportInfo.some((info) =>
										selectedStatuses.includes(info.status)
									) && (
										<CountsExportedListItem
											key={
												index +
												title +
												client.id +
												exportInfo.length
											}
											client={client}
											exportInfo={exportInfo}
										/>
									)
							)}
						</Accordion>
					</Box>
				)}
			</CardBody>
		</Card>
	);
};

export default CountsExportedList;

// Helper function to get color based on status
export const getColorForStatus = (status: string): string => {
	switch (status) {
		case "Billed":
			return "green";
		case "Pending":
			return "yellow";
		case "Rejected":
			return "red";
		case "Empty":
			return "gray";
		default:
			return "darkgray";
	}
};
