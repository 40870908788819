import {
	TableContainer,
	Table,
	Thead,
	Tr,
	Th,
	Tbody,
	Td,
	Input,
	Tooltip,
	Flex,
	Select,
} from "@chakra-ui/react";
import {
	useGetMachinesByContractTypeQuery,
	useGetMachinesQuery,
} from "../../api/machineApi";
import { CheckCircleIcon, SmallCloseIcon } from "@chakra-ui/icons";
import { useState } from "react";
import Loading from "../Loading";
import debounce from "lodash.debounce";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { ContractType } from "../../api/contractApi";
import { skipToken } from "@reduxjs/toolkit/query";

const MachinesTable = () => {
	const [sageId, setSageId] = useState<string | undefined>(undefined);
	const [serialNumber, setSerialNumber] = useState<string | undefined>(
		undefined
	);
	const [contractType, setContractType] = useState<ContractType | undefined>(
		undefined
	);

	const { data: machines, isFetching: isFechingMachines } =
		useGetMachinesQuery({
			includeClients: true,
			sageId,
			serialNumber,
		});

	const {
		data: machinesByContractType,
		isFetching: isFetchingByContractType,
	} = useGetMachinesByContractTypeQuery(contractType ?? skipToken);

	const data = machinesByContractType ? machinesByContractType : machines;
	const isFetching = isFechingMachines || isFetchingByContractType;

	return (
		<TableContainer>
			<Table
				layout="auto"
				variant="striped"
				colorScheme="gray"
				size={"sm"}
			>
				<Thead>
					<Tr border={"1px solid #edf2f7"} borderRadius={"3xl"}>
						<Th>
							<Input
								variant="flushed"
								placeholder="ID Sage"
								type="number"
								onChange={debounce((e) => {
									setSageId(e.target.value);
								}, 500)}
							/>
						</Th>
						<Th maxWidth="200px" whiteSpace="normal">
							<Input
								variant="flushed"
								placeholder="Numero de série"
								type="text"
								onChange={debounce((e) => {
									setSerialNumber(e.target.value);
								}, 500)}
							/>
						</Th>
						<Th>
							<Select
								placeholder="Tipo de contrato"
								onChange={(e) =>
									setContractType(
										e.target.value as ContractType
									)
								}
								value={contractType}
							>
								{Object.keys(ContractType).map((key) => (
									<option
										key={key}
										value={
											ContractType[
												key as keyof typeof ContractType
											]
										}
									>
										{key.charAt(0).toUpperCase() +
											key.slice(1)}
									</option>
								))}
							</Select>
						</Th>
						{/*<Th>Marca</Th>
						<Th>Modelo</Th>
						<Th>Ativo</Th>
						<Th></Th> */}
					</Tr>
					<Tr>
						<Th>ID Sage</Th>
						<Th maxWidth="200px" whiteSpace="normal">
							Numero de série
						</Th>
						<Th maxWidth="250px" whiteSpace="normal">
							Cliente
						</Th>
						<Th>Marca</Th>
						<Th>Modelo</Th>
						<Th>Ativo</Th>
						<Th></Th>
					</Tr>
				</Thead>
				<Tbody>
					{isFetching ? (
						<Loading />
					) : (
						(data ?? []).map((machine) => (
							<Tr key={machine.id}>
								<Td>{machine.sageId}</Td>
								<Td
									maxWidth={{ base: "", md: "200px" }}
									whiteSpace={{ base: "", md: "normal" }}
								>
									{machine.serialNumber}
								</Td>
								<Td maxWidth="250px" whiteSpace="normal">
									<Flex alignItems={"center"} gap={2}>
										{machine.office?.client?.name ?? ""}
										{machine.office?.name && (
											<Tooltip
												label={
													machine.office?.name ?? ""
												}
												fontSize="md"
											>
												<span>
													<HiOutlineOfficeBuilding />
												</span>
											</Tooltip>
										)}
									</Flex>
								</Td>
								<Td>{machine.brand.name}</Td>
								<Td>{machine.model.name}</Td>
								<Td>
									{machine.active ? (
										<CheckCircleIcon color="green" />
									) : (
										<SmallCloseIcon color="red" />
									)}
								</Td>

								<Td>
									{/* <Flex gap={3}>
									<IconButton
										aria-label="Ver detalhes da máquina"
										// onClick={() =>
										// 	// navigate(
										// 	// 	`/admin/contracts/${id}/details`
										// 	// )
										// }
									>
										<SearchIcon color={"white"} />
									</IconButton>
									<IconButton
										aria-label="Editar máquina"
										variant={"outline"}
										colorScheme="blue"
										// onClick={() =>
										// 	// handleContractEdit(id)
										// }
									>
										<FiEdit2 />
									</IconButton>
								</Flex> */}
								</Td>
							</Tr>
						))
					)}
				</Tbody>
			</Table>
		</TableContainer>
	);
};

export default MachinesTable;
